import React from "react";

const Input = ({
  labelClassName,
  labelText,
  type,
  name,
  value,
  onChange,
  required,
  max,
  min,
  placeholder,
  className,
  readOnly,
  asterisk
}) => {
  return (
    <div className="d-flex flex-column">
      <label className={labelClassName}>
        {labelText} {asterisk == "referralLink" ? <span className="text-danger-asterisk"></span>: <span className="text-danger-asterisk">*</span>}
      </label>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
        max={max}
        min={min}
        placeholder={placeholder}
        readOnly={readOnly}
        className={className}
      />
    </div>
  );
};

export default Input;
