import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
import getCountrys from "../services/api/getCountry";
import { countryList } from "../constants/countryList";

const Selects = ({ setSelectedCountry, country, selectedCountry, status }) => {
  const { t, i18n } = useTranslation();
  const [getCountries, setGetCountries] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [countrys, setCountry] = useState([]);
  const { setSelectData, setSelectDays, setSelectPrice, currentLocalization, setPackageType } =
    useContext(AuthUserContext);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getCountry = async () => {
    try {
      let array = [];
      setCountry(countryList);
      if (countryList) {
        for (let item of countryList) {
          let object = {
            label: t(`countryName.${item?.name?.common}`) || "",
            value: item?.name?.common || "",
            flag: item?.flags?.png || "",
            aliases:
              item?.name?.common === "Saudi Arabia"
                ? ["Hajj", "Umrah"]
                : item?.name?.common === "Iraq"
                  ? ["Ziarat"]
                  : [],
          };
          array.push(object);
        }
      }
      setGetCountries(array);
      setIsLoading(false);
    } catch (e) {
      console.log("Error fetching countries:", e);
      setIsLoading(false);
    }
  };

  const handleSelectChange = (selectedOptions) => {
    setPackageType("")
    let labels = selectedOptions?.map((option) => option.value);
    let selectedCountries = [];
    labels.forEach((label) => {
      countrys?.forEach((country) => {
        if (country?.name?.common === label) {
          selectedCountries.push(country.cca2);
        }
      });
    });
    if (status === "country-filter" && selectedOptions?.length === 0) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(
        "country",
        selectedCountry.length > 0 ? selectedCountry[0] : ""
      );
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
    }
    const formattedCountries = selectedCountries.join(",");
    let newUrl = window.location.href.split("?")[0];
    if (selectedCountries.length > 0) {
      newUrl += `?country=${formattedCountries}`;
    }
    window.history.pushState({ path: newUrl }, "", newUrl);
    setSelectedCountry(selectedCountries);
    setSelectedValues(selectedOptions);
    setMenuIsOpen(false);
  };

  const findCountryByCca2 = () => {
    let array = [];
    if (Array.isArray(country)) {
      country.forEach((cca2) => {
        const foundCountry = countrys.find((c) => c.cca2 === cca2);
        if (foundCountry) {
          let object = {
            label: t(`countryName.${foundCountry.name.common}`),
            value: foundCountry.name.common,
            flag: foundCountry.flags.png,
          };
          array.push(object);
        }
      });
    } else {
      selectedCountry?.forEach((cca2) => {
        const foundCountry = countrys.find((c) => c.cca2 === cca2);
        if (foundCountry) {
          let object = {
            label: t(`countryName.${foundCountry.name.common}`),
            value: foundCountry.name.common,
            flag: foundCountry.flags.png,
          };
          array.push(object);
        }
      });
    }
    setSelectedValues(array);
  };

  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "flex-start",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "start",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      direction: i18n.language === "ar" ? "rtl" : "ltr",
    }),
    control: (provided) => ({
      ...provided,
      direction: i18n.language === "ar" ? "rtl" : "ltr",
    }),
    input: (provided) => ({
      ...provided,
      color: "white",
      caretColor: "white",
    }),
  };

  const handleSelectFocus = () => {
    setIsFocused(true);
    setMenuIsOpen(true);
  };

  const handleSelectBlur = () => {
    setIsFocused(false);
    setMenuIsOpen(false);
  };
  const handleClickOutside = (event) => {
    if (
      event.target.closest(".react-select__control") === null &&
      event.target.closest(".react-select__menu") === null
    ) {
      setMenuIsOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };
  const customFilterOption = (option, inputValue) => {
    const label =
      option.label && typeof option.label === "string"
        ? option.label.toLowerCase()
        : "";
    const value =
      option.value && typeof option.value === "string"
        ? option.value.toLowerCase()
        : "";
    const aliases = Array.isArray(option.data.aliases)
      ? option.data.aliases.map((alias) => alias.toLowerCase())
      : [];
    return (
      label.includes(inputValue.toLowerCase()) ||
      value.includes(inputValue.toLowerCase()) ||
      aliases.some((alias) => alias.includes(inputValue.toLowerCase()))
    );
  };
  useEffect(() => {
    getCountry();
  }, [i18n.language]);
  useEffect(() => {
    if (!isLoading && selectedValues.length === 0) {
      const timeout = setTimeout(() => {
        setMenuIsOpen(true);
      }, 500);
      return () => clearTimeout(timeout);
    } else {
      setMenuIsOpen(false);
    }
  }, [isLoading, selectedValues]);
  useEffect(() => {
    findCountryByCca2();
  }, [country, getCountries]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <Select
        isMulti
        options={getCountries}
        styles={customStyles}
        getOptionLabel={(option) => (
          <div style={{ display: "flex", alignItems: "start" }}>
            <img
              src={option.flag}
              alt={option.label}
              style={{ width: "30px", height: "20px", marginRight: "5px" }}
            />
            <span>{option.label}</span>
          </div>
        )}
        value={selectedValues}
        onChange={handleSelectChange}
        onFocus={handleSelectFocus}
        onBlur={handleSelectBlur}
        onKeyDown={handleKeyDown}
        placeholder={!isFocused ? t("home.part26") : ""}
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        filterOption={customFilterOption}
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default Selects;