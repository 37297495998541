import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import { toast } from "react-toastify";
import getUserListing from "../../services/packages/getUsers";
import DataTables from "../../component/DataTable";
import { useNavigate } from "react-router-dom";

const UserListing = () => {
  const { t } = useTranslation();
  const { currentLocalization, setToastId } = useContext(AuthUserContext);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [sortedUsers, setSortedUsers] = useState([]);
  const columns = [
    {
      name: <span className="dataTable-text">{t("table.username")}</span>,
      key: "name",
      selector: (row) => row.package,
      cell: (row) => (
        <span className="text-start column-text">{row?.name}</span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="dataTable-text">{t("table.phone_no")}</span>,
      key: "phone_no",
      cell: (row) => (
        <span className="text-start column-text">{row?.phone_no}</span>
      ),
      sortable: true,
      width: "200px",
    },
    {
      name: <span className="dataTable-text">{t("table.email")}</span>,
      key: "email",
      selector: (row) => <span className="column-text">{row?.email}</span>,
      sortable: true,
      width: "280px",
    },
    {
      name: <span className="dataTable-text">{t("table.countries")}</span>,
      key: "countries",
      selector: (row) => (
        <span>
          {row.countries.map((country, index) => (
            <span key={country} className="column-text">
              {country}
              {index !== row.countries.length - 1 && ", "}
            </span>
          ))}
        </span>
      ),
      sortable: true,
      width: "400px",
    },
    {
      name: <span className="dataTable-text ">{t("detailsPage.part12")}</span>,
      key: "createdAt",
      selector: (row) => (
        <span className="column-text">
          {new Date(row.createdAt).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          })}
        </span>
      ),
      sortable: true,
      width: currentLocalization == "in" ? "170px": "160px" ,
    },
  ];
  const handleSort = async (column, direction) => {
    const sortedData = [...users].sort((a, b) => {
      if (a[column.key] < b[column.key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[column.key] > b[column.key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setSortedUsers(sortedData);
  };
  const getUsers = async () => {
    try {
      const response = await getUserListing();
      setUsers(response.data);
      setSortedUsers(response.data);
    } catch (err) {
      if (err.response?.status == 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        localStorage.clear();
        navigate("/log-in");
      } else {
        console.log("err", err);
        const id = toast.error(err.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getUsers();
  }, [currentLocalization]);
  return (
    <Sidebar>
      <Container>
        <Row>
          <Col
            className={currentLocalization == "ar" ? "text-end" : "text-start"}
          >
            <h2 className="package-h2">{t("dataUsage.usersListing")}</h2>
            <div className="mt-4">
              <DataTables
                columns={columns}
                data={sortedUsers.length ? sortedUsers : users}
                loading={loading}
                handleSort={handleSort}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Sidebar>
  );
};

export default UserListing;
