import React, { useContext, useEffect, useState } from "react";
import { TbWorld } from "react-icons/tb";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
import { images } from "../utils/image";
import { discount } from "../constants/localstorageKeys";
import esimVaultSvg from "../assets/images/esimVault.svg";
import airaloSvg from "../assets/images/airalo.svg";
import esimgoSvg from "../assets/images/esimgo.svg";
const OfferCardFilter = ({
  item,
  key,
  index,
  offers,
  exchangeRate = 1,
  currencySymbol = "$",
  getCountryss,
  user,
  packageType,
}) => {
  const [discountPer, setDiscountPer] = useState("");
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  const navigate = useNavigate();
  const specialIndices = [];
  for (let i = 1; i < offers.length; i += 3) {
    specialIndices.push(i);
  }
  useEffect(() => {
    setDiscountPer(discount);
  }, []);
  return (
    <>
      <Col
        md={4}
        xs={12}
        className={
          specialIndices.includes(index) == true
            ? "offer-card-box-yellow mb-3 p-0 m-2 ms-2"
            : "offer-card-box-green mb-3 p-0 m-2 ms-2"
        }
        key={index}
      >
        <Col
          md={12}
          xs={12}
          className={
            specialIndices.includes(index) == true
              ? "offer-card-box-top-yellow offer-card-top-box"
              : "offer-card-box-top-green offer-card-top-box"
          }
        >
          <div>
            <Row
              className={
                currentLocalization == "ar"
                  ? " d-flex "
                  : "d-flex flex-row"
              }
            >
              <Col
                md={12}
                xs={12}
                className={`d-flex align-items-start ${
                  currentLocalization == "ar" ? "flex-row-reverse" : "flex-row"
                }`}
                style={{ height: "60px" }}
              >
                <div className="">
                  <img
                    src={
                      item?.key === "esimgo"
                        ? esimgoSvg
                        : item?.key === "esimVault"
                        ? esimVaultSvg
                        : airaloSvg
                    }
                    alt="offer-icon"
                    width={50}
                  />
                </div>

                <h3
                  className={`global-h3 ${
                    item?.key === "airalo" ? "airlo-text" : "mt-2"
                  }  ${
                    currentLocalization == "ar"
                      ? "me-2 text-end ms-2"
                      : "ms-2 me-2 text-start"
                  }`}
                >
                  {item?.name}
                </h3>
              </Col>
              <Row
                className=" ms-1  pt-1"
                style={{ height: "60px", margin: "6px" }}
              >
                <Col
                  md={3}
                  xs={4}
                  className={`d-flex flex-column  p-0 ${
                    currentLocalization == "ar"
                      ? "text-end pe-4 pt-2 border-line"
                      : "text-start border-line pt-2"
                  }`}
                >
                  <span className="offer-validity-span-filter">
                    {t("home.part18")}
                  </span>
                  {currentLocalization === "ar" ? (
                    <div className="d-flex align-items-center justify-content-end">
                      <span className="offer-days-span-filter">
                        {t("home.part21")}
                      </span>
                      <span> &nbsp;{item?.validity}</span>
                    </div>
                  ) : (
                    <span className="offer-days-span-filter">
                      {item?.validity} {t("home.part21")}
                    </span>
                  )}
                </Col>
                <Col
                  md={4}
                  xs={4}
                  className={`d-flex flex-column pt-2 ${
                    currentLocalization == "ar"
                      ? "text-end pe-2 border-padding-offerCard-data"
                      : currentLocalization == "fr"
                      ? "text-start border-padding-offerCard-data-fr"
                      : "text-start border-padding-offerCard-data"
                  }`}
                  style={{ borderRight: "1px solid #DEDEDE" }}
                >
                  <span className="offer-validity-span-filter">
                    {t("home.part19")}
                  </span>
                  {currentLocalization === "ar" ? (
                    <div className="d-flex flex-row align-items-center justify-content-end">
                      <span className="offer-days-span-filter">
                        {t("home.part22")}
                      </span>
                      <span> &nbsp;{item?.data}</span>
                    </div>
                  ) : (
                    <span className="offer-days-span-filter ">
                      {item?.data} {t("home.part22")}
                    </span>
                  )}
                </Col>
                <Col
                  md={5}
                  xs={4}
                  className={`d-flex flex-wrap flex-column pt-2 ${
                    currencySymbol == "Rp"
                      ? "border-padding-offerCard-rp"
                      : "border-padding-offerCard"
                  } mb-2 ${
                    currentLocalization == "ar" ? "text-end p-0" : "text-start"
                  }`}
                >
                  <span className="offer-validity-span-filter">
                    {t("home.part20")}
                  </span>
                  <span
                    className={`offer-days-span-filter text-wrap  ${
                      user && user.parent_id ? "" : "price-offer"
                    }`}
                  >
                    {currencySymbol == "Rp" ? (
                      <>
                        {currencySymbol || "$"}&nbsp;
                        {(item?.price * exchangeRate).toFixed(0)}
                      </>
                    ) : (
                      <>
                        {currencySymbol || "$"}&nbsp;
                        {(item?.price * exchangeRate).toFixed(2)}
                      </>
                    )}
                  </span>
                </Col>
              </Row>
              <div
                className={`${user && user.parent_id ? "" : "cards-discount"}`}
              >
                {user && user.parent_id ? null : (
                  <Row style={{ minHeight: "30px" }} className="mt-2">
                    <Col
                      md={7}
                      xs={8}
                      className={`d-flex align-items-center m-0 ${
                        currentLocalization == "ar"
                          ? "d-flex justify-content-end pe-4 border-line discount-box-right"
                          : "text-start border-line discount-box "
                      }`}
                    >
                      <span className="offer-validity-span  offercard-discount">
                        {t("discountedOffer")}
                      </span>
                    </Col>
                    <Col
                      md={5}
                      xs={4}
                      className={`d-flex align-items-center  ${
                        currencySymbol == "Rp"
                          ? "border-padding-offerCard-rp"
                          : "border-padding-offerCard"
                      }  ${
                        currentLocalization == "ar"
                          ? "d-flex justify-content-end pe-2 discount-4-box-right"
                          : "text-start discount-4-box"
                      }`}
                    >
                      <span
                        className={
                          currentLocalization == "ar"
                            ? " price-padding text-wrap"
                            : "offer-days-span text-wrap"
                        }
                      >
                        {currencySymbol == "Rp" ? (
                      <>
                        {currencySymbol}&nbsp;
                        {(item?.discountedPrice * exchangeRate).toFixed(0)}
                      </>
                    ) : (
                      <>
                        {currencySymbol}&nbsp;
                        {(item?.discountedPrice * exchangeRate).toFixed(2)}
                      </>
                    )}
                        
                      </span>
                    </Col>
                  </Row>
                )}
              </div>

              <Col md={12} xs={12} style={{ height: "60px" }} className="pt-2 pe-1">
                <ul className="pt-1 p-0">
                  <li
                    className={` d-flex ${
                      currentLocalization == "ar"
                        ? "text-end p-0 flex-row-reverse align-items-start"
                        : "text-start  ps-1"
                    }`}
                  >
                    <span>
                      <TbWorld size={17} color="#004940" />
                    </span>
                    <span
                      className={`offers-works-span-filter ${
                        currentLocalization == "ar" ? "pe-1 pt-1" : "ps-1"
                      }`}
                    >
                      {t("countryFilter.part31")}{" "}
                      <span class="getCountryss-bold">
                        {t(`countryName.${getCountryss}`)}
                      </span>
                      {item?.countries_count
                        ? ` & ${item?.countries_count - 1} ${t(
                            "countryFilter.part32"
                          )}`
                        : ""}
                    </span>
                  </li>
                </ul>
              </Col>
            </Row>
            <Col md={12} xs={12} className={`text-start p-2`}>
              <div className="d-grid">
                <button
                  className="view-offer-button"
                  size="lg"
                  onClick={() => {
                    navigate(
                      `/offer-detail?id=${item?.orignalName}&key=${item?.key}`
                    );
                    window.scroll(0, 0);
                  }}
                >
                  {t("home.part23")}
                </button>
              </div>
            </Col>
          </div>
        </Col>
      </Col>
    </>
  );
};

export default OfferCardFilter;
