import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/high-res.css";
import { AuthUserContext } from "../context/context";

const PhoneInputCode = ({
  labelClassName,
  labelText,
  setPhoneNumber,
  phoneNumber,
}) => {
  const [countryCode, setCountryCode] = useState("");
  const { currentLocalization } = useContext(AuthUserContext);
  const handlePhoneChange = (value, country) => {
    setPhoneNumber(value);
    setCountryCode(`+${country.dialCode}`);
  };
  return (
    <div className="form-group">
      <label htmlFor="mobile" className={labelClassName}>
        {labelText}
      </label>
      <div className="input-group input-group-sm">
        <PhoneInput
          country={"gb"}
          value={phoneNumber}
          onChange={handlePhoneChange}
          inputProps={{
            name: "mobile",
            required: true,
            autoFocus: true,
          }}
          enableSearch={false}
          containerClass={`phone-input-container ${
            currentLocalization == "ar"
              ? "react-tel-input-right"
              : "react-tel-input"
          } `}
          preferredCountries={["gb"]}
          inputClass="phone-input"
        />
      </div>
    </div>
  );
};

export default PhoneInputCode;
