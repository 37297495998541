const API_URL = process.env.REACT_APP_API_URL;
const routes = {
  BASE: API_URL,
  ESIM_LIST: API_URL + "/api/v1/esims-list",
  BUNDLE_DETAILS: API_URL + "/api/v1/bundle-details",
  CHECKOUT_SESSION: API_URL + "/api/v1/create-checkout-session",
  CHECKOUT_TOPUP_SESSION: API_URL + "/api/v1//create-stripe-topUp-session",
  // Auth
  REGISTER: API_URL + "/api/v1/register",
  VERIFY_OTP: API_URL + "/api/v1/verify-otp",
  LOGIN: API_URL + "/api/v1/login",
  RESEND_OTP: API_URL + "/api/v1/resend-otp",
  FORGET_PASSWORD: API_URL + "/api/v1/forget",
  UPDATE_PASSWORD: API_URL + "/api/v1/password-change",

  // User Routes
  PACKAGES_LIST: API_URL + "/api/v1/getUserPackages",
  USER_LISTING: API_URL + "/api/v1/getUserListing",
  GET_ALL_SIM_PACKAGE: API_URL + "/api/v1/getPackages",
  UPDATE_PACKAGE: API_URL + "/api/v1/updatePackagesPrice",
  ADD_LABEL: API_URL + "/api/v1/addLabel",
  //Top-up
  GET_TOPUP_PACKAGE_LIST: API_URL + "/api/v1/get-topup-packages",
  GET_TOPUP_LIST: API_URL + "/api/v1/get-topups",

  //Transactions
  GET_TRANSACTIONS_LIST: API_URL + "/api/v1/getUserTransactions",
};
export default routes;
