const  currencySymbols = [
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/albania.png",
    CountryName: "Albania",
    Currency: "Lek",
    Code: "ALL",
    Symbol: "Lek",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/afghanistan.png",
    CountryName: "Afghanistan",
    Currency: "Afghani",
    Code: "AFN",
    Symbol: "؋",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/argentina.png",
    CountryName: "Argentina",
    Currency: "Peso",
    Code: "ARS",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/aruba.png",
    CountryName: "Aruba",
    Currency: "Guilder",
    Code: "AWG",
    Symbol: "ƒ",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/australia.png",
    CountryName: "Australia",
    Currency: "Dollar",
    Code: "AUD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/azerbaijan.png",
    CountryName: "Azerbaijan",
    Currency: "Manat",
    Code: "AZN",
    Symbol: "₼",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/bahamas.png",
    CountryName: "Bahamas",
    Currency: "Dollar",
    Code: "BSD",
    Symbol: "$",
  },
  {
    Flag: "https://flagcdn.com/w320/dz.png",
    CountryName: "Algeria",
    Code: "DZD",
    Currency: "Algerian Dinar",
    Symbol: "د.ج"
  },
  {
    Flag: "https://flagcdn.com/w320/ad.png",
    CountryName: "Andorra",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/ao.png",
    CountryName: "Angola",
    Code: "AOA",
    Currency: "Angolan Kwanza",
    Symbol: "Kz"
  },
  {
    Flag: "https://flagcdn.com/w320/ag.png",
    CountryName: "Antigua and Barbuda",
    Code: "XCD",
    Currency: "East Caribbean Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/am.png",
    CountryName: "Armenia",
    Code: "AMD",
    Currency: "Armenian Dram",
    Symbol: "֏"
  },
  {
    Flag: "https://flagcdn.com/w320/at.png",
    CountryName: "Austria",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/barbados.png",
    CountryName: "Barbados",
    Currency: "Dollar",
    Code: "BBD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/belarus.png",
    CountryName: "Belarus",
    Currency: "Ruble",
    Code: "BYR",
    Symbol: "p.",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/belize.png",
    CountryName: "Belize",
    Currency: "Dollar",
    Code: "BZD",
    Symbol: "BZ$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/bermuda.png",
    CountryName: "Bermuda",
    Currency: "Dollar",
    Code: "BMD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/bolivia.png",
    CountryName: "Bolivia",
    Currency: "Boliviano",
    Code: "BOB",
    Symbol: "$b",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/Bosnia_and_Herzegovina.png",
    CountryName: "Bosnia and Herzegovina",
    Currency: "Convertible Marka",
    Code: "BAM",
    Symbol: "KM",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/botswana.png",
    CountryName: "Botswana",
    Currency: "Pula",
    Code: "BWP",
    Symbol: "P",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/bulgaria.png",
    CountryName: "Bulgaria",
    Currency: "Lev",
    Code: "BGN",
    Symbol: "лв",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/brazil.png",
    CountryName: "Brazil",
    Currency: "Real",
    Code: "BRL",
    Symbol: "R$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/brunei.png",
    CountryName: "Brunei",
    Currency: "Darussalam Dollar",
    Code: "BND",
    Symbol: "$",
  },
  {
    Flag: "https://flagcdn.com/w320/bh.png",
    CountryName: "Bahrain",
    Code: "BHD",
    Currency: "Bahraini Dinar",
    Symbol: ".د.ب"
  },
  {
    Flag: "https://flagcdn.com/w320/bd.png",
    CountryName: "Bangladesh",
    Code: "BDT",
    Currency: "Bangladeshi Taka",
    Symbol: "৳"
  },
  {
    Flag: "https://flagcdn.com/w320/be.png",
    CountryName: "Belgium",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/bj.png",
    CountryName: "Benin",
    Code: "XOF",
    Currency: "West African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/bt.png",
    CountryName: "Bhutan",
    Code: "BTN",
    Currency: "Bhutanese Ngultrum",
    Symbol: "Nu."
  },
  {
    Flag: "https://flagcdn.com/w320/io.png",
    CountryName: "British Indian Ocean Territory",
    Code: "USD",
    Currency: "United States Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/bf.png",
    CountryName: "Burkina Faso",
    Code: "XOF",
    Currency: "West African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/bi.png",
    CountryName: "Burundi",
    Code: "BIF",
    Currency: "Burundian Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/cambodia.png",
    CountryName: "Cambodia",
    Currency: "Riel",
    Code: "KHR",
    Symbol: "៛",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/canada.png",
    CountryName: "Canada",
    Currency: "Dollar",
    Code: "CAD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/Cayman_Islands.png",
    CountryName: "Cayman",
    Currency: "Dollar",
    Code: "KYD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/chile.png",
    CountryName: "Chile",
    Currency: "Peso",
    Code: "CLP",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/china.png",
    CountryName: "China",
    Currency: "Yuan Renminbi",
    Code: "CNY",
    Symbol: "¥",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/colombia.png",
    CountryName: "Colombia",
    Currency: "Peso",
    Code: "COP",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/costarica.png",
    CountryName: "Costa Rica",
    Currency: "Colon",
    Code: "CRC",
    Symbol: "₡",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/croatia.png",
    CountryName: "Croatia",
    Currency: "Kuna",
    Code: "HRK",
    Symbol: "kn",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/cuba.png",
    CountryName: "Cuba",
    Currency: "Peso",
    Code: "CUP",
    Symbol: "₱",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/czechrepublic.png",
    CountryName: "Czech Republic",
    Currency: "Koruna",
    Code: "CZK",
    Symbol: "Kč",
  },
  {
    Flag: "https://flagcdn.com/w320/cm.png",
    CountryName: "Cameroon",
    Code: "XAF",
    Currency: "Central African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/cv.png",
    CountryName: "Cape Verde",
    Code: "CVE",
    Currency: "Cape Verdean Escudo",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/bq.png",
    CountryName: "Caribbean Netherlands",
    Code: "USD",
    Currency: "United States Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/cf.png",
    CountryName: "Central African Republic",
    Code: "XAF",
    Currency: "Central African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/td.png",
    CountryName: "Chad",
    Code: "XAF",
    Currency: "Central African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/km.png",
    CountryName: "Comoros",
    Code: "KMF",
    Currency: "Comorian Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/cg.png",
    CountryName: "Congo",
    Code: "XAF",
    Currency: "Central African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/ci.png",
    CountryName: "Côte d’Ivoire",
    Code: "XOF",
    Currency: "West African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/cw.png",
    CountryName: "Curaçao",
    Code: "ANG",
    Currency: "Netherlands Antillean Guilder",
    Symbol: "ƒ"
  },
  {
    Flag: "https://flagcdn.com/w320/cy.png",
    CountryName: "Cyprus",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/denmark.png",
    CountryName: "Denmark",
    Currency: "Krone",
    Code: "DKK",
    Symbol: "kr",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/dominicanrepublic.png",
    CountryName: "Dominican Republic",
    Currency: "Peso",
    Code: "DOP",
    Symbol: "RD$",
  },
  {
    Flag: "https://flagcdn.com/w320/dj.png",
    CountryName: "Djibouti",
    Code: "DJF",
    Currency: "Djiboutian Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/dm.png",
    CountryName: "Dominica",
    Code: "XCD",
    Currency: "East Caribbean Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/egypt.png",
    CountryName: "Egypt",
    Currency: "Pound",
    Code: "EGP",
    Symbol: "£",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/elsalvador.png",
    CountryName: "El Salvador",
    Currency: "Colon",
    Code: "SVC",
    Symbol: "$",
  },
  {
    Flag: "https://flagcdn.com/w320/ec.png",
    CountryName: "Ecuador",
    Code: "USD",
    Currency: "United States Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/gq.png",
    CountryName: "Equatorial Guinea",
    Code: "XAF",
    Currency: "Central African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/er.png",
    CountryName: "Eritrea",
    Code: "ERN",
    Currency: "Eritrean Nakfa",
    Symbol: "Nfk"
  },
  {
    Flag: "https://flagcdn.com/w320/et.png",
    CountryName: "Ethiopia",
    Code: "ETB",
    Currency: "Ethiopian Birr",
    Symbol: "Br"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/estonia.png",
    CountryName: "Estonia",
    Currency: "Kroon",
    Code: "EEK",
    Symbol: "kr",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/euro.png",
    CountryName: "Euro Member",
    Currency: "Euro",
    Code: "EUR",
    Symbol: "€",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/falklandislands.png",
    CountryName: "Falkland Islands",
    Currency: "Pound",
    Code: "FKP",
    Symbol: "£",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/fiji.png",
    CountryName: "Fiji",
    Currency: "Dollar",
    Code: "FJD",
    Symbol: "$",
  },
  {
    Flag: "https://flagcdn.com/w320/fi.png",
    CountryName: "Finland",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/fr.png",
    CountryName: "France",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/gf.png",
    CountryName: "French Guiana",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/pf.png",
    CountryName: "French Polynesia",
    Code: "XPF",
    Currency: "CFP Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/georgia.png",
    CountryName: "Georgia",
    Currency: "Lari",
    Code: "GEL",
    Symbol: "₾",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/ghana.png",
    CountryName: "Ghana",
    Currency: "Cedis",
    Code: "GHC",
    Symbol: "¢",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/gibraltar.png",
    CountryName: "Gibraltar",
    Currency: "Pound",
    Code: "GIP",
    Symbol: "£",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/guatemala.png",
    CountryName: "Guatemala",
    Currency: "Quetzal",
    Code: "GTQ",
    Symbol: "Q",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/guernsey.png",
    CountryName: "Guernsey",
    Currency: "Pound",
    Code: "GGP",
    Symbol: "£",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/guyana.png",
    CountryName: "Guyana",
    Currency: "Dollar",
    Code: "GYD",
    Symbol: "$",
  },
  {
    Flag: "https://flagcdn.com/w320/gm.png",
    CountryName: "Gambia",
    Code: "GMD",
    Currency: "Gambian Dalasi",
    Symbol: "D"
  },
  {
    Flag: "https://flagcdn.com/w320/de.png",
    CountryName: "Germany",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/gr.png",
    CountryName: "Greece",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/gd.png",
    CountryName: "Grenada",
    Code: "XCD",
    Currency: "East Caribbean Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/gp.png",
    CountryName: "Guadeloupe",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/gu.png",
    CountryName: "Guam",
    Code: "USD",
    Currency: "United States Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/gn.png",
    CountryName: "Guinea",
    Code: "GNF",
    Currency: "Guinean Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/gw.png",
    CountryName: "Guinea-Bissau",
    Code: "XOF",
    Currency: "West African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/ht.png",
    CountryName: "Haiti",
    Code: "HTG",
    Currency: "Haitian Gourde",
    Symbol: "G"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/honduras.png",
    CountryName: "Honduras",
    Currency: "Lempira",
    Code: "HNL",
    Symbol: "L",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/hongkong.png",
    CountryName: "Hong Kong",
    Currency: "Dollar",
    Code: "HKD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/hungary.png",
    CountryName: "Hungary",
    Currency: "Forint",
    Code: "HUF",
    Symbol: "Ft",
  },
  {
    Flag: "https://flagcdn.com/w320/ie.png",
    CountryName: "Ireland",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/it.png",
    CountryName: "Italy",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/iceland.png",
    CountryName: "Iceland",
    Currency: "Krona",
    Code: "ISK",
    Symbol: "kr",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/india.png",
    CountryName: "India",
    Currency: "Rupee",
    Code: "INR",
    Symbol: "₹",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/indonesia.png",
    CountryName: "Indonesia",
    Currency: "Rupiah",
    Code: "IDR",
    Symbol: "Rp",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/iran.png",
    CountryName: "Iran",
    Currency: "Rial",
    Code: "IRR",
    Symbol: "﷼",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/isleofman.png",
    CountryName: "Isle of Man",
    Currency: "Pound",
    Code: "IMP",
    Symbol: "£",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/israel.png",
    CountryName: "Israel",
    Currency: "Shekel",
    Code: "ILS",
    Symbol: "₪",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/jamaica.png",
    CountryName: "Jamaica",
    Currency: "Dollar",
    Code: "JMD",
    Symbol: "J$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/japan.png",
    CountryName: "Japan",
    Currency: "Yen",
    Code: "JPY",
    Symbol: "¥",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/jersey.png",
    CountryName: "Jersey",
    Currency: "Pound",
    Code: "JEP",
    Symbol: "£",
  },
  {
    Flag: "https://flagcdn.com/w320/jo.png",
    CountryName: "Jordan",
    Code: "JOD",
    Currency: "Jordanian Dinar",
    Symbol: "د.ا"
  },
  {
    Flag: "https://flagcdn.com/w320/ke.png",
    CountryName: "Kenya",
    Code: "KES",
    Currency: "Kenyan Shilling",
    Symbol: "Sh"
  },
  {
    Flag: "https://flagcdn.com/w320/ki.png",
    CountryName: "Kiribati",
    Code: "AUD",
    Currency: "Australian Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/xk.png",
    CountryName: "Kosovo",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/kw.png",
    CountryName: "Kuwait",
    Code: "KWD",
    Currency: "Kuwaiti Dinar",
    Symbol: "د.ك"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/kazakhstan.png",
    CountryName: "Kazakhstan",
    Currency: "Tenge",
    Code: "KZT",
    Symbol: "лв",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/northkorea.png",
    CountryName: "Korea (North)",
    Currency: "Won",
    Code: "KPW",
    Symbol: "₩",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/southkorea.png",
    CountryName: "Korea (South)",
    Currency: "Won",
    Code: "KRW",
    Symbol: "₩",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/kyrgyzstan.png",
    CountryName: "Kyrgyzstan",
    Currency: "Som",
    Code: "KGS",
    Symbol: "лв",
  },
  {
    Flag: "https://flagcdn.com/w320/ls.png",
    CountryName: "Lesotho",
    Code: "LSL",
    Currency: "Lesotho Loti",
    Symbol: "L"
  },
  {
    Flag: "https://flagcdn.com/w320/ly.png",
    CountryName: "Libya",
    Code: "LYD",
    Currency: "Libyan Dinar",
    Symbol: "ل.د"
  },
  {
    Flag: "https://flagcdn.com/w320/li.png",
    CountryName: "Liechtenstein",
    Code: "CHF",
    Currency: "Swiss Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/lu.png",
    CountryName: "Luxembourg",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/laos.png",
    CountryName: "Laos",
    Currency: "Kip",
    Code: "LAK",
    Symbol: "₭",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/latvia.png",
    CountryName: "Latvia",
    Currency: "Lat",
    Code: "LVL",
    Symbol: "Ls",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/lebanon.png",
    CountryName: "Lebanon",
    Currency: "Pound",
    Code: "LBP",
    Symbol: "£",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/liberia.png",
    CountryName: "Liberia",
    Currency: "Dollar",
    Code: "LRD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/lithuania.png",
    CountryName: "Lithuania",
    Currency: "Litas",
    Code: "LTL",
    Symbol: "Lt",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/macedonia.png",
    CountryName: "Macedonia",
    Currency: "Denar",
    Code: "MKD",
    Symbol: "ден",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/malaysia.png",
    CountryName: "Malaysia",
    Currency: "Ringgit",
    Code: "MYR",
    Symbol: "RM",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/mauritius.png",
    CountryName: "Mauritius",
    Currency: "Rupee",
    Code: "MUR",
    Symbol: "₨",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/mexico.png",
    CountryName: "Mexico",
    Currency: "Peso",
    Code: "MXN",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/mongolia.png",
    CountryName: "Mongolia",
    Currency: "Tughrik",
    Code: "MNT",
    Symbol: "₮",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/mozambique.png",
    CountryName: "Mozambique",
    Currency: "Metical",
    Code: "MZN",
    Symbol: "MT",
  },
  {
    Flag: "https://flagcdn.com/w320/mo.png",
    CountryName: "Macau",
    Code: "MOP",
    Currency: "Macanese Pataca",
    Symbol: "MOP$"
  },
  {
    Flag: "https://flagcdn.com/w320/mg.png",
    CountryName: "Madagascar",
    Code: "MGA",
    Currency: "Malagasy Ariary",
    Symbol: "Ar"
  },
  {
    Flag: "https://flagcdn.com/w320/mw.png",
    CountryName: "Malawi",
    Code: "MWK",
    Currency: "Malawian Kwacha",
    Symbol: "MK"
  },
  {
    Flag: "https://flagcdn.com/w320/mv.png",
    CountryName: "Maldives",
    Code: "MVR",
    Currency: "Maldivian Rufiyaa",
    Symbol: "Rf"
  },
  {
    Flag: "https://flagcdn.com/w320/ml.png",
    CountryName: "Mali",
    Code: "XOF",
    Currency: "West African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/mt.png",
    CountryName: "Malta",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/mh.png",
    CountryName: "Marshall Islands",
    Code: "USD",
    Currency: "United States Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/mq.png",
    CountryName: "Martinique",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/mr.png",
    CountryName: "Mauritania",
    Code: "MRU",
    Currency: "Mauritanian Ouguiya",
    Symbol: "UM"
  },
  {
    Flag: "https://flagcdn.com/w320/fm.png",
    CountryName: "Micronesia",
    Code: "USD",
    Currency: "United States Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/md.png",
    CountryName: "Moldova",
    Code: "MDL",
    Currency: "Moldovan Leu",
    Symbol: "lei"
  },
  {
    Flag: "https://flagcdn.com/w320/mc.png",
    CountryName: "Monaco",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/me.png",
    CountryName: "Montenegro",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/ma.png",
    CountryName: "Morocco",
    Code: "MAD",
    Currency: "Moroccan Dirham",
    Symbol: "د.م."
  },
  {
    Flag: "https://flagcdn.com/w320/mm.png",
    CountryName: "Myanmar",
    Code: "MMK",
    Currency: "Myanmar Kyat",
    Symbol: "Ks"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/namibia.png",
    CountryName: "Namibia",
    Currency: "Dollar",
    Code: "NAD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/nepal.png",
    CountryName: "Nepal",
    Currency: "Rupee",
    Code: "NPR",
    Symbol: "₨",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/netherlands.png",
    CountryName: "Netherlands",
    Currency: "Antilles Guilder",
    Code: "ANG",
    Symbol: "ƒ",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/newzealand.png",
    CountryName: "New Zealand",
    Currency: "Dollar",
    Code: "NZD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/nicaragua.png",
    CountryName: "Nicaragua",
    Currency: "Cordoba",
    Code: "NIO",
    Symbol: "C$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/nigeria.png",
    CountryName: "Nigeria",
    Currency: "Naira",
    Code: "NGN",
    Symbol: "₦",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/norway.png",
    CountryName: "Norway",
    Currency: "Krone",
    Code: "NOK",
    Symbol: "kr",
  },
  {
    Flag: "https://flagcdn.com/w320/nr.png",
    CountryName: "Nauru",
    Code: "AUD",
    Currency: "Australian Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/nc.png",
    CountryName: "New Caledonia",
    Code: "XPF",
    Currency: "CFP Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/ne.png",
    CountryName: "Niger",
    Code: "XOF",
    Currency: "West African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/kp.png",
    CountryName: "North Korea",
    Code: "KPW",
    Currency: "North Korean Won",
    Symbol: "₩"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/oman.png",
    CountryName: "Oman",
    Currency: "Rial",
    Code: "OMR",
    Symbol: "﷼",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/pakistan.png",
    CountryName: "Pakistan",
    Currency: "Rupee",
    Code: "PKR",
    Symbol: "₨",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/panama.png",
    CountryName: "Panama",
    Currency: "Balboa",
    Code: "PAB",
    Symbol: "B/.",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/paraguay.png",
    CountryName: "Paraguay",
    Currency: "Guarani",
    Code: "PYG",
    Symbol: "Gs",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/peru.png",
    CountryName: "Peru",
    Currency: "Nuevo Sol",
    Code: "PEN",
    Symbol: "S/.",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/philippines.png",
    CountryName: "Philippines",
    Currency: "Peso",
    Code: "PHP",
    Symbol: "₱",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/poland.png",
    CountryName: "Poland",
    Currency: "Zloty",
    Code: "PLN",
    Symbol: "zł",
  },
  {
    Flag: "https://flagcdn.com/w320/pw.png",
    CountryName: "Palau",
    Code: "USD",
    Currency: "United States Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/ps.png",
    CountryName: "Palestine",
    Code: "ILS",
    Currency: "Israeli New Shekel",
    Symbol: "₪"
  },
  {
    Flag: "https://flagcdn.com/w320/pg.png",
    CountryName: "Papua New Guinea",
    Code: "PGK",
    Currency: "Papua New Guinean Kina",
    Symbol: "K"
  },
  {
    Flag: "https://flagcdn.com/w320/pt.png",
    CountryName: "Portugal",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/pr.png",
    CountryName: "Puerto Rico",
    Code: "USD",
    Currency: "United States Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/qatar.png",
    CountryName: "Qatar",
    Currency: "Riyal",
    Code: "QAR",
    Symbol: "﷼",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/romania.png",
    CountryName: "Romania",
    Currency: "New Leu",
    Code: "RON",
    Symbol: "lei",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/russia.png",
    CountryName: "Russia",
    Currency: "Ruble",
    Code: "RUB",
    Symbol: "₽",
  },
  {
    Flag: "https://flagcdn.com/w320/re.png",
    CountryName: "Réunion",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/rw.png",
    CountryName: "Rwanda",
    Code: "RWF",
    Currency: "Rwandan Franc",
    Symbol: "FRw"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/sainthelena.png",
    CountryName: "Saint Helena",
    Currency: "Pound",
    Code: "SHP",
    Symbol: "£",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/saudiarabia.png",
    CountryName: "Saudi Arabia",
    Currency: "Riyal",
    Code: "SAR",
    Symbol: "﷼",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/serbia.png",
    CountryName: "Serbia",
    Currency: "Dinar",
    Code: "RSD",
    Symbol: "Дин.",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/seychelles.png",
    CountryName: "Seychelles",
    Currency: "Rupee",
    Code: "SCR",
    Symbol: "₨",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/singapore.png",
    CountryName: "Singapore",
    Currency: "Dollar",
    Code: "SGD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/solomonislands.png",
    CountryName: "Solomon Islands",
    Currency: "Dollar",
    Code: "SBD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/somalia.png",
    CountryName: "Somalia",
    Currency: "Shilling",
    Code: "SOS",
    Symbol: "S",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/southafrica.png",
    CountryName: "South Africa",
    Currency: "Rand",
    Code: "ZAR",
    Symbol: "R",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/srilanka.png",
    CountryName: "Sri Lanka",
    Currency: "Rupee",
    Code: "LKR",
    Symbol: "₨",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/sweden.png",
    CountryName: "Sweden",
    Currency: "Krona",
    Code: "SEK",
    Symbol: "kr",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/switzerland.png",
    CountryName: "Switzerland",
    Currency: "Franc",
    Code: "CHF",
    Symbol: "CHF",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/suriname.png",
    CountryName: "Suriname",
    Currency: "Dollar",
    Code: "SRD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/syria.png",
    CountryName: "Syria",
    Currency: "Pound",
    Code: "SYP",
    Symbol: "£",
  },
  {
    Flag: "https://flagcdn.com/w320/kn.png",
    CountryName: "Saint Kitts and Nevis",
    Code: "XCD",
    Currency: "East Caribbean Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/lc.png",
    CountryName: "Saint Lucia",
    Code: "XCD",
    Currency: "East Caribbean Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/vc.png",
    CountryName: "Saint Vincent and the Grenadines",
    Code: "XCD",
    Currency: "East Caribbean Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/ws.png",
    CountryName: "Samoa",
    Code: "WST",
    Currency: "Samoan Tala",
    Symbol: "T"
  },
  {
    Flag: "https://flagcdn.com/w320/sm.png",
    CountryName: "San Marino",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/st.png",
    CountryName: "São Tomé and Príncipe",
    Code: "STN",
    Currency: "São Tomé and Príncipe Dobra",
    Symbol: "Db"
  },
  {
    Flag: "https://flagcdn.com/w320/sn.png",
    CountryName: "Senegal",
    Code: "XOF",
    Currency: "West African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/sl.png",
    CountryName: "Sierra Leone",
    Code: "SLL",
    Currency: "Sierra Leonean Leone",
    Symbol: "Le"
  },
  {
    Flag: "https://flagcdn.com/w320/sk.png",
    CountryName: "Slovakia",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/si.png",
    CountryName: "Slovenia",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/kr.png",
    CountryName: "South Korea",
    Code: "KRW",
    Currency: "South Korean Won",
    Symbol: "₩"
  },
  {
    Flag: "https://flagcdn.com/w320/ss.png",
    CountryName: "South Sudan",
    Code: "SSP",
    Currency: "South Sudanese Pound",
    Symbol: "£"
  },
  {
    Flag: "https://flagcdn.com/w320/es.png",
    CountryName: "Spain",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/sd.png",
    CountryName: "Sudan",
    Code: "SDG",
    Currency: "Sudanese Pound",
    Symbol: "ج.س."
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/taiwan.png",
    CountryName: "Taiwan",
    Currency: "New Dollar",
    Code: "TWD",
    Symbol: "NT$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/thailand.png",
    CountryName: "Thailand",
    Currency: "Baht",
    Code: "THB",
    Symbol: "฿",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/trinidadandtobago.png",
    CountryName: "Trinidad and Tobago",
    Currency: "Dollar",
    Code: "TTD",
    Symbol: "TT$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/turkey.png",
    CountryName: "Turkey",
    Currency: "Lira",
    Code: "TRL",
    Symbol: "₺",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/tuvalu.png",
    CountryName: "Tuvalu",
    Currency: "Dollar",
    Code: "TVD",
    Symbol: "$",
  },
  {
    Flag: "https://flagcdn.com/w320/tj.png",
    CountryName: "Tajikistan",
    Code: "TJS",
    Currency: "Tajikistani Somoni",
    Symbol: "SM"
  },
  {
    Flag: "https://flagcdn.com/w320/tz.png",
    CountryName: "Tanzania",
    Code: "TZS",
    Currency: "Tanzanian Shilling",
    Symbol: "TSh"
  },
  {
    Flag: "https://flagcdn.com/w320/tl.png",
    CountryName: "Timor-Leste",
    Code: "USD",
    Currency: "United States Dollar",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/tg.png",
    CountryName: "Togo",
    Code: "XOF",
    Currency: "West African CFA Franc",
    Symbol: "Fr"
  },
  {
    Flag: "https://flagcdn.com/w320/to.png",
    CountryName: "Tonga",
    Code: "TOP",
    Currency: "Tongan Paʻanga",
    Symbol: "$"
  },
  {
    Flag: "https://flagcdn.com/w320/tn.png",
    CountryName: "Tunisia",
    Code: "TND",
    Currency: "Tunisian Dinar",
    Symbol: "د.ت"
  },
  {
    Flag: "https://flagcdn.com/w320/tm.png",
    CountryName: "Turkmenistan",
    Code: "TMT",
    Currency: "Turkmenistani Manat",
    Symbol: "m"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/ukraine.png",
    CountryName: "Ukraine",
    Currency: "Hryvna",
    Code: "UAH",
    Symbol: "₴",
  },
  {
    Flag: "https://flagcdn.com/w320/ug.png",
    CountryName: "Uganda",
    Code: "UGX",
    Currency: "Ugandan Shilling",
    Symbol: "USh"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/unitedkingdom.png",
    CountryName: "United Kingdom",
    Currency: "Pound",
    Code: "GBP",
    Symbol: "£",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/unitedstates.png",
    CountryName: "United States",
    Currency: "Dollar",
    Code: "USD",
    Symbol: "$",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/uruguay.png",
    CountryName: "Uruguay",
    Currency: "Peso",
    Code: "UYU",
    Symbol: "$U",
  },
  {
    Flag: "https://flagcdn.com/w320/ae.png",
    CountryName: "United Arab Emirates",
    Code: "AE",
    Currency: "AED",
    Symbol: "د.إ"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/uzbekistan.png",
    CountryName: "Uzbekistan",
    Currency: "Som",
    Code: "UZS",
    Symbol: "лв",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/venezuela.png",
    CountryName: "Venezuela",
    Currency: "Bolivar Fuerte",
    Code: "VEF",
    Symbol: "Bs",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/vietnam.png",
    CountryName: "Viet Nam",
    Currency: "Dong",
    Code: "VND",
    Symbol: "₫",
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/yemen.png",
    CountryName: "Yemen",
    Currency: "Rial",
    Code: "YER",
    Symbol: "﷼",
  },
  {
    Flag: "https://flagcdn.com/w320/vu.png",
    CountryName: "Vanuatu",
    Code: "VUV",
    Currency: "Vanuatu Vatu",
    Symbol: "Vt"
  },
  {
    Flag: "https://flagcdn.com/w320/va.png",
    CountryName: "Vatican City",
    Code: "EUR",
    Currency: "Euro",
    Symbol: "€"
  },
  {
    Flag: "https://flagcdn.com/w320/vn.png",
    CountryName: "Vietnam",
    Code: "VND",
    Currency: "Vietnamese Dong",
    Symbol: "₫"
  },
  {
    Flag: "https://flagcdn.com/w320/zm.png",
    CountryName: "Zambia",
    Code: "ZMW",
    Currency: "Zambian Kwacha",
    Symbol: "ZK"
  },
  {
    Flag: "https://www.currencyremitapp.com/wp-content/themes/currencyremitapp/images/countryimages/zimbabwe.png",
    CountryName: "Zimbabwe",
    Currency: "Dollar",
    Code: "ZWD",
    Symbol: "Z$",
  },
];

const countryList = [
  {
    flag: "https://flagcdn.com/w320/sa.png",
    countryName: "Saudi Arabia",
    countryCode: "SA",
    currency: "SAR"
  },
  {
    flag: "https://flagcdn.com/w320/ae.png",
    countryName: "United Arab Emirates",
    countryCode: "AE",
    currency: "AED"
  },
  {
    flag: "https://flagcdn.com/w320/tr.png",
    countryName: "Turkey",
    countryCode: "TR",
    currency: "USD"
  },
  {
    flag: "https://flagcdn.com/w320/om.png",
    countryName: "Oman",
    countryCode: "OM",
    currency: "USD"
  },
  {
    flag: "https://flagcdn.com/w320/in.png",
    countryName: "India",
    countryCode: "IN",
    currency: "USD"
  },
  {
    flag: "https://flagcdn.com/w320/pk.png",
    countryName: "Pakistan",
    countryCode: "PK",
    currency: "USD"
  },
  {
    flag: "https://flagcdn.com/w320/gb.png",
    countryName: "United Kingdom",
    countryCode: "GB",
    currency: "GBP"
  },
  {
    flag: "https://flagcdn.com/w320/us.png",
    countryName: "United States",
    countryCode: "US",
    currency: "USD"
  },
  {
    flag: "https://flagcdn.com/w320/ca.png",
    countryName: "Canada",
    countryCode: "CA",
    currency: "CAD"
  },
  {
    flag: "https://flagcdn.com/w320/it.png",
    countryName: "Italy",
    countryCode: "IT",
    currency: "USD"
  },
  {
    flag: "https://flagcdn.com/w320/br.png",
    countryName: "Brazil",
    countryCode: "BR",
    currency: "USD"
  },
  {
    flag: "https://flagcdn.com/w320/tn.png",
    countryName: "Tunisia",
    countryCode: "TN",
    currency: "USD"
  },
  {
    flag: "https://flagcdn.com/w320/dz.png",
    countryName: "Algeria",
    countryCode: "DZ",
    currency: "USD"
  },
  {
    flag: "https://flagcdn.com/w320/ma.png",
    countryName: "Morocco",
    countryCode: "MA",
    currency: "USD"
  },
];
const countryCode = [
  {
    countryName: "US",
    countryCurrency: "USD"
  },
  {
    countryName: "GB",
    countryCurrency: "GBP"
  },
  {
    countryName: "CA",
    countryCurrency: "CAD"
  },
  {
    countryName: "ID",
    countryCurrency: "IDR"
  },
  {
    countryName: "AE",
    countryCurrency: "AED"
  }, 
  {
    countryName: "SA",
    countryCurrency: "SAR"
  },
  {
    countryName: "DE",
    countryCurrency: "EUR"
  },
  {
    countryName: "IT",
    countryCurrency: "EUR"
  }, {
    countryName: "FR",
    countryCurrency: "EUR"
  },
  {
    countryName: "ES",
    countryCurrency: "EUR"
  },
  {
    countryName: "GR",
    countryCurrency: "EUR"
  },
  {
    countryName: "GR",
    countryCurrency: "EUR"
  },
  {
    countryName: "PT",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "AT",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "BE",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "HR",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "CY",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "EE",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "IE",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "LV",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "LT",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "LU",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "MT",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "NL",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "SK",
    countryCurrency: "EUR"
  }
  ,
  {
    countryName: "SI",
    countryCurrency: "EUR"
  }
]
const countryCurrency = [
  {country_code: "AF", country_name: "Afghanistan", currency_code: "AFN"},
  {country_code: "AL", country_name: "Albania", currency_code: "ALL"},
  {country_code: "DZ", country_name: "Algeria", currency_code: "DZD"},
  {country_code: "AO", country_name: "Angola", currency_code: "AOA"},
  {country_code: "AR", country_name: "Argentina", currency_code: "ARS"},
  {country_code: "AM", country_name: "Armenia", currency_code: "AMD"},
  {country_code: "AU", country_name: "Australia", currency_code: "AUD"},
  {country_code: "AT", country_name: "Austria", currency_code: "EUR"},
  {country_code: "AZ", country_name: "Azerbaijan", currency_code: "AZN"},
  {country_code: "BS", country_name: "Bahamas", currency_code: "BSD"},
  {country_code: "BH", country_name: "Bahrain", currency_code: "BHD"},
  {country_code: "BD", country_name: "Bangladesh", currency_code: "BDT"},
  {country_code: "BB", country_name: "Barbados", currency_code: "BBD"},
  {country_code: "BY", country_name: "Belarus", currency_code: "BYN"},
  {country_code: "BE", country_name: "Belgium", currency_code: "EUR"},
  {country_code: "BZ", country_name: "Belize", currency_code: "BZD"},
  {country_code: "BJ", country_name: "Benin", currency_code: "XOF"},
  {country_code: "BT", country_name: "Bhutan", currency_code: "BTN"},
  {country_code: "BO", country_name: "Bolivia", currency_code: "BOB"},
  {country_code: "BA", country_name: "Bosnia and Herzegovina", currency_code: "BAM"},
  {country_code: "BW", country_name: "Botswana", currency_code: "BWP"},
  {country_code: "BR", country_name: "Brazil", currency_code: "BRL"},
  {country_code: "BN", country_name: "Brunei", currency_code: "BND"},
  {country_code: "BG", country_name: "Bulgaria", currency_code: "BGN"},
  {country_code: "BF", country_name: "Burkina Faso", currency_code: "XOF"},
  {country_code: "BI", country_name: "Burundi", currency_code: "BIF"},
  {country_code: "KH", country_name: "Cambodia", currency_code: "KHR"},
  {country_code: "CM", country_name: "Cameroon", currency_code: "XAF"},
  {country_code: "CA", country_name: "Canada", currency_code: "CAD"},
  {country_code: "CV", country_name: "Cape Verde", currency_code: "CVE"},
  {country_code: "CF", country_name: "Central African Republic", currency_code: "XAF"},
  {country_code: "TD", country_name: "Chad", currency_code: "XAF"},
  {country_code: "CL", country_name: "Chile", currency_code: "CLP"},
  {country_code: "CN", country_name: "China", currency_code: "CNY"},
  {country_code: "CO", country_name: "Colombia", currency_code: "COP"},
  {country_code: "KM", country_name: "Comoros", currency_code: "KMF"},
  {country_code: "CG", country_name: "Congo - Brazzaville", currency_code: "XAF"},
  {country_code: "CD", country_name: "Congo - Kinshasa", currency_code: "CDF"},
  {country_code: "CR", country_name: "Costa Rica", currency_code: "CRC"},
  {country_code: "CI", country_name: "Côte d’Ivoire", currency_code: "XOF"},
  {country_code: "HR", country_name: "Croatia", currency_code: "HRK"},
  {country_code: "CU", country_name: "Cuba", currency_code: "CUP"},
  {country_code: "CY", country_name: "Cyprus", currency_code: "EUR"},
  {country_code: "CZ", country_name: "Czechia", currency_code: "CZK"},
  {country_code: "DK", country_name: "Denmark", currency_code: "DKK"},
  {country_code: "DJ", country_name: "Djibouti", currency_code: "DJF"},
  {country_code: "DM", country_name: "Dominica", currency_code: "XCD"},
  {country_code: "DO", country_name: "Dominican Republic", currency_code: "DOP"},
  {country_code: "EC", country_name: "Ecuador", currency_code: "USD"},
  {country_code: "EG", country_name: "Egypt", currency_code: "EGP"},
  {country_code: "SV", country_name: "El Salvador", currency_code: "USD"},
  {country_code: "GQ", country_name: "Equatorial Guinea", currency_code: "XAF"},
  {country_code: "ER", country_name: "Eritrea", currency_code: "ERN"},
  {country_code: "EE", country_name: "Estonia", currency_code: "EUR"},
  {country_code: "ET", country_name: "Ethiopia", currency_code: "ETB"},
  {country_code: "FJ", country_name: "Fiji", currency_code: "FJD"},
  {country_code: "FI", country_name: "Finland", currency_code: "EUR"},
  {country_code: "FR", country_name: "France", currency_code: "EUR"},
  {country_code: "GA", country_name: "Gabon", currency_code: "XAF"},
  {country_code: "GM", country_name: "Gambia", currency_code: "GMD"},
  {country_code: "GE", country_name: "Georgia", currency_code: "GEL"},
  {country_code: "DE", country_name: "Germany", currency_code: "EUR"},
  {country_code: "GH", country_name: "Ghana", currency_code: "GHS"},
  {country_code: "GR", country_name: "Greece", currency_code: "EUR"},
  {country_code: "GD", country_name: "Grenada", currency_code: "XCD"},
  {country_code: "GT", country_name: "Guatemala", currency_code: "GTQ"},
  {country_code: "GN", country_name: "Guinea", currency_code: "GNF"},
  {country_code: "GW", country_name: "Guinea-Bissau", currency_code: "XOF"},
  {country_code: "GY", country_name: "Guyana", currency_code: "GYD"},
  {country_code: "HT", country_name: "Haiti", currency_code: "HTG"},
  {country_code: "HN", country_name: "Honduras", currency_code: "HNL"},
  {country_code: "HU", country_name: "Hungary", currency_code: "HUF"},
  {country_code: "IS", country_name: "Iceland", currency_code: "ISK"},
  {country_code: "IN", country_name: "India", currency_code: "INR"},
  {country_code: "ID", country_name: "Indonesia", currency_code: "IDR"},
  {country_code: "IR", country_name: "Iran", currency_code: "IRR"},
  {country_code: "IQ", country_name: "Iraq", currency_code: "IQD"},
  {country_code: "IE", country_name: "Ireland", currency_code: "EUR"},
  {country_code: "IL", country_name: "Israel", currency_code: "ILS"},
  {country_code: "IT", country_name: "Italy", currency_code: "EUR"},
  {country_code: "JM", country_name: "Jamaica", currency_code: "JMD"},
  {country_code: "JP", country_name: "Japan", currency_code: "JPY"},
  {country_code: "JO", country_name: "Jordan", currency_code: "JOD"},
  {country_code: "KZ", country_name: "Kazakhstan", currency_code: "KZT"},
  {country_code: "KE", country_name: "Kenya", currency_code: "KES"},
  {country_code: "KI", country_name: "Kiribati", currency_code: "AUD"},
  {country_code: "KP", country_name: "North Korea", currency_code: "KPW"},
  {country_code: "KR", country_name: "South Korea", currency_code: "KRW"},
  {country_code: "KW", country_name: "Kuwait", currency_code: "KWD"},
  {country_code: "KG", country_name: "Kyrgyzstan", currency_code: "KGS"},
  {country_code: "LA", country_name: "Laos", currency_code: "LAK"},
  {country_code: "LV", country_name: "Latvia", currency_code: "EUR"},
  {country_code: "LB", country_name: "Lebanon", currency_code: "LBP"},
  {country_code: "LS", country_name: "Lesotho", currency_code: "LSL"},
  {country_code: "LR", country_name: "Liberia", currency_code: "LRD"},
  {country_code: "LY", country_name: "Libya", currency_code: "LYD"},
  {country_code: "LI", country_name: "Liechtenstein", currency_code: "CHF"},
  {country_code: "LT", country_name: "Lithuania", currency_code: "EUR"},
  {country_code: "LU", country_name: "Luxembourg", currency_code: "EUR"},
  {country_code: "MG", country_name: "Madagascar", currency_code: "MGA"},
  {country_code: "MW", country_name: "Malawi", currency_code: "MWK"},
  {country_code: "MY", country_name: "Malaysia", currency_code: "MYR"},
  {country_code: "MV", country_name: "Maldives", currency_code: "MVR"},
  {country_code: "ML", country_name: "Mali", currency_code: "XOF"},
  {country_code: "MT", country_name: "Malta", currency_code: "EUR"},
  {country_code: "MH", country_name: "Marshall Islands", currency_code: "USD"},
  {country_code: "MR", country_name: "Mauritania", currency_code: "MRU"},
  {country_code: "MU", country_name: "Mauritius", currency_code: "MUR"},
  {country_code: "MX", country_name: "Mexico", currency_code: "MXN"},
  {country_code: "FM", country_name: "Micronesia", currency_code: "USD"},
  {country_code: "MD", country_name: "Moldova", currency_code: "MDL"},
  {country_code: "MC", country_name: "Monaco", currency_code: "EUR"},
  {country_code: "MN", country_name: "Mongolia", currency_code: "MNT"},
  {country_code: "ME", country_name: "Montenegro", currency_code: "EUR"},
  {country_code: "MA", country_name: "Morocco", currency_code: "MAD"},
  {country_code: "MZ", country_name: "Mozambique", currency_code: "MZN"},
  {country_code: "MM", country_name: "Myanmar (Burma)", currency_code: "MMK"},
  {country_code: "NA", country_name: "Namibia", currency_code: "NAD"},
  {country_code: "NR", country_name: "Nauru", currency_code: "AUD"},
  {country_code: "NP", country_name: "Nepal", currency_code: "NPR"},
  {country_code: "NL", country_name: "Netherlands", currency_code: "EUR"},
  {country_code: "NZ", country_name: "New Zealand", currency_code: "NZD"},
  {country_code: "NI", country_name: "Nicaragua", currency_code: "NIO"},
  {country_code: "NE", country_name: "Niger", currency_code: "XOF"},
  {country_code: "NG", country_name: "Nigeria", currency_code: "NGN"},
  {country_code: "MK", country_name: "North Macedonia", currency_code: "MKD"},
  {country_code: "NO", country_name: "Norway", currency_code: "NOK"},
  {country_code: "OM", country_name: "Oman", currency_code: "OMR"},
  {country_code: "PK", country_name: "Pakistan", currency_code: "PKR"},
  {country_code: "PW", country_name: "Palau", currency_code: "USD"},
  {country_code: "PA", country_name: "Panama", currency_code: "PAB"},
  {country_code: "PG", country_name: "Papua New Guinea", currency_code: "PGK"},
  {country_code: "PY", country_name: "Paraguay", currency_code: "PYG"},
  {country_code: "PE", country_name: "Peru", currency_code: "PEN"},
  {country_code: "PH", country_name: "Philippines", currency_code: "PHP"},
  {country_code: "PL", country_name: "Poland", currency_code: "PLN"},
  {country_code: "PT", country_name: "Portugal", currency_code: "EUR"},
  {country_code: "QA", country_name: "Qatar", currency_code: "QAR"},
  {country_code: "RO", country_name: "Romania", currency_code: "RON"},
  {country_code: "RU", country_name: "Russia", currency_code: "RUB"},
  {country_code: "RW", country_name: "Rwanda", currency_code: "RWF"},
  {country_code: "KN", country_name: "Saint Kitts and Nevis", currency_code: "XCD"},
  {country_code: "LC", country_name: "Saint Lucia", currency_code: "XCD"},
  {country_code: "VC", country_name: "Saint Vincent and the Grenadines", currency_code: "XCD"},
  {country_code: "WS", country_name: "Samoa", currency_code: "WST"},
  {country_code: "SM", country_name: "San Marino", currency_code: "EUR"},
  {country_code: "ST", country_name: "São Tomé and Príncipe", currency_code: "STN"},
  {country_code: "SA", country_name: "Saudi Arabia", currency_code: "SAR"},
  {country_code: "SN", country_name: "Senegal", currency_code: "XOF"},
  {country_code: "RS", country_name: "Serbia", currency_code: "RSD"},
  {country_code: "SC", country_name: "Seychelles", currency_code: "SCR"},
  {country_code: "SL", country_name: "Sierra Leone", currency_code: "SLL"},
  {country_code: "SG", country_name: "Singapore", currency_code: "SGD"},
  {country_code: "SK", country_name: "Slovakia", currency_code: "EUR"},
  {country_code: "SI", country_name: "Slovenia", currency_code: "EUR"},
  {country_code: "SB", country_name: "Solomon Islands", currency_code: "SBD"},
  {country_code: "SO", country_name: "Somalia", currency_code: "SOS"},
  {country_code: "ZA", country_name: "South Africa", currency_code: "ZAR"},
  {country_code: "SS", country_name: "South Sudan", currency_code: "SSP"},
  {country_code: "ES", country_name: "Spain", currency_code: "EUR"},
  {country_code: "LK", country_name: "Sri Lanka", currency_code: "LKR"},
  {country_code: "SD", country_name: "Sudan", currency_code: "SDG"},
  {country_code: "SR", country_name: "Suriname", currency_code: "SRD"},
  {country_code: "SZ", country_name: "Swaziland", currency_code: "SZL"},
  {country_code: "SE", country_name: "Sweden", currency_code: "SEK"},
  {country_code: "CH", country_name: "Switzerland", currency_code: "CHF"},
  {country_code: "SY", country_name: "Syria", currency_code: "SYP"},
  {country_code: "TW", country_name: "Taiwan", currency_code: "TWD"},
  {country_code: "TJ", country_name: "Tajikistan", currency_code: "TJS"},
  {country_code: "TZ", country_name: "Tanzania", currency_code: "TZS"},
  {country_code: "TH", country_name: "Thailand", currency_code: "THB"},
  {country_code: "TL", country_name: "Timor-Leste", currency_code: "USD"},
  {country_code: "TG", country_name: "Togo", currency_code: "XOF"},
  {country_code: "TO", country_name: "Tonga", currency_code: "TOP"},
  {country_code: "TT", country_name: "Trinidad and Tobago", currency_code: "TTD"},
  {country_code: "TN", country_name: "Tunisia", currency_code: "TND"},
  {country_code: "TR", country_name: "Turkey", currency_code: "TRY"},
  {country_code: "TM", country_name: "Turkmenistan", currency_code: "TMT"},
  {country_code: "TV", country_name: "Tuvalu", currency_code: "AUD"},
  {country_code: "UG", country_name: "Uganda", currency_code: "UGX"},
  {country_code: "UA", country_name: "Ukraine", currency_code: "UAH"},
  {country_code: "AE", country_name: "United Arab Emirates", currency_code: "AED"},
  {country_code: "GB", country_name: "United Kingdom", currency_code: "GBP"},
  {country_code: "US", country_name: "United States", currency_code: "USD"},
  {country_code: "UY", country_name: "Uruguay", currency_code: "UYU"},
  {country_code: "UZ", country_name: "Uzbekistan", currency_code: "UZS"},
  {country_code: "VU", country_name: "Vanuatu", currency_code: "VUV"},
  {country_code: "VE", country_name: "Venezuela", currency_code: "VES"},
  {country_code: "VN", country_name: "Vietnam", currency_code: "VND"},
  {country_code: "YE", country_name: "Yemen", currency_code: "YER"},
  {country_code: "ZM", country_name: "Zambia", currency_code: "ZMW"},
  {country_code: "ZW", country_name: "Zimbabwe", currency_code: "ZWL"}
]
module.exports = {
  currencySymbols,
  countryList,
  countryCode,
  countryCurrency
};
