const AUTH_TOKEN_KEY = "AUTH_TOKEN";
const USER_DETAILS = "USER_DETAILS";
const AUTH_REFRESH_TOKEN_KEY = "AUTH_REFRESH_TOKEN";
const CURRENCY = "CURRENCY";
const COUNTRY_CURRENCY = "COUNTRY_CURRENCY";
const SIM_PURCHASE = "SIM_PURCHASE";
const discount = "10";

export {
  AUTH_TOKEN_KEY,
  AUTH_REFRESH_TOKEN_KEY,
  USER_DETAILS,
  CURRENCY,
  COUNTRY_CURRENCY,
  SIM_PURCHASE,
  discount,
};
