import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { RxCross1 } from "react-icons/rx";
import { AuthUserContext } from "../context/context";

const MultiSelect = ({
  value,
  name,
  selectedOptions,
  setSelectedOptions,
  setFormInfo,
  formInfo,
  formName,
  label,
  type,
  options,
  labelClassName,
  labelText,
}) => {
  const { currentLocalization } = useContext(AuthUserContext);
  const { t } = useTranslation();
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    if (type === "single") {
      setSelectedOptions([selectedValue]);
      setFormInfo((prevErrors) => ({
        ...prevErrors,
        [formName]: selectedValue,
      }));
    } else if (type === "multiple") {
      if (!selectedOptions?.includes(selectedValue)) {
        const newSelectedOptions = [...selectedOptions, selectedValue];
        setSelectedOptions(newSelectedOptions);
        setFormInfo((prevErrors) => ({
          ...prevErrors,
          [formName]: newSelectedOptions,
        }));
      }
    }
  };
  const handleRemoveOption = (removedOption) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option !== removedOption
    );
    setSelectedOptions(updatedOptions);
    setFormInfo((prevErrors) => ({
      ...prevErrors,
      [formName]: updatedOptions,
    }));
  };

  return (
    <div className="multi-select-container">
      <label className={labelClassName}>
        {labelText} <span className="text-danger-asterisk">*</span>
      </label>
      <div className="select-wrapper">
        <select
          name={name}
          id=""
          value={type === "multiple" ? label : label}
          onChange={handleSelectChange}
          className={`login-input-left   w-100 ${
            currentLocalization == "ar"
              ? "text-end pe-3 textField-ar"
              : "text-start textField"
          } w-100`}
          dir={currentLocalization == "ar" ? "rtl" : "ltr"}
        >
          <option value="" selected>
            {label}
          </option>
          {options?.map((option, index) => (
            <option key={index} value={option?.CountryName}>
              {t(`countryName.${option?.CountryName}`)}
            </option>
          ))}
        </select>
      </div>
      <div
        className={`selected-options-signup d-flex  ${
          currentLocalization == "ar" ? "flex-row-reverse" : "flex-row"
        }`}
      >
        {selectedOptions &&
          selectedOptions?.map((option) => (
            <div
              key={option}
              className={`selected-option-signup mt-3 ${
                currentLocalization == "ar"
                  ? "flex-row-reverse align-items-center"
                  : " d-flex flex-row align-items-center"
              }`}
            >
              <span>{t(`countryName.${option}`)}</span>
              <RxCross1
                className="remove-option"
                onClick={() => handleRemoveOption(option)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultiSelect;
