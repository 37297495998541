import Dropdown from "react-bootstrap/Dropdown";
import { CgProfile } from "react-icons/cg";
import { RiLogoutCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AuthUserContext } from "../context/context";
import {
  AUTH_TOKEN_KEY,
  SIM_PURCHASE,
  USER_DETAILS,
} from "../constants/localstorageKeys";

function NavMenu({ user }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { currentLocalization, setUser } = useContext(AuthUserContext);
  return (
    <Dropdown className="dropDown-icons-arrow">
      <Dropdown.Toggle className="user-menu-button " id="dropdown-basic">
        <div className="d-flex align-items-center">
          <div className="d-flex flex-column text-start ms-2">
            <span className="user-name-span">
              {user?.name && user.name.length > 8
                ? user.name.substring(0, 8) + "..."
                : user.name}
            </span>
            {/* <span className="user-name-span-down mt-1">{user?.role}</span> */}
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu style={{ zIndex: "999999" }}>
        <Dropdown.Item
          href="#/action-1"
          className={`d-flex align-items-center  mt-2 ${
            currentLocalization == "ar" && "flex-row-reverse"
          }`}
          onClick={() => navigate("/profile-setting")}
        >
          {currentLocalization == "ar" ? (
            <>
              <CgProfile size={20} />
              {t("dataUsage.profileSettings")}&nbsp;&nbsp;&nbsp;
            </>
          ) : (
            <>
              <CgProfile size={20} /> &nbsp;&nbsp;&nbsp;
              {t("dataUsage.profileSettings")}
            </>
          )}
        </Dropdown.Item>
        <Dropdown.Item
          href="#/action-2"
          className={`d-flex align-items-center  mt-2 ${
            currentLocalization == "ar" && "flex-row-reverse"
          }`}
          onClick={() => {
            navigate("/");
            localStorage.removeItem(AUTH_TOKEN_KEY);
            localStorage.removeItem(USER_DETAILS);
            localStorage.removeItem(SIM_PURCHASE);
            window.scroll(0, 0);
            setUser([]);
          }}
        >
          {currentLocalization == "ar" ? (
            <>
              <RiLogoutCircleLine size={20} />
              {t("dataUsage.logout")}&nbsp;&nbsp;&nbsp;
            </>
          ) : (
            <>
              <RiLogoutCircleLine size={20} /> &nbsp;&nbsp;&nbsp;
              {t("dataUsage.logout")}
            </>
          )}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default NavMenu;
