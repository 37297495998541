import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "../../component/Sidebar/SideBar";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../../context/context";
import { USER_DETAILS } from "../../constants/localstorageKeys";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ListingDataTables from "../../component/ListingDataTable";
import getAllSimPackage from "../../services/packages/getAllSimPackage";
import { FaSearch } from "react-icons/fa";
import DatatableMenu from "../../component/DataTableMenu";
import esimVaultSvg from "../../assets/images/esimVault.svg";
import airaloSvg from "../../assets/images/airalo.svg";
import esimgoSvg from "../../assets/images/esimgo.svg";
import Modals from "../../component/Modal";
import updatePackage from "../../services/packages/updatePackage";
const MyPackage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentLocalization, setToastId } = useContext(AuthUserContext);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [error, setError] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc");
  const [perPage, setPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [percentage, setPercentage] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const isFirstRun = useRef(true);
  
  const columns = [
    {
      name: <span className="dataTable-text">{t("table.vendor")}</span>,
      key: "package.key",
      selector: (row) => row.package,
      cell: (row) => (
        <div className="text-start column-text d-flex align-items-center">
          <div className="" style={{ width: "55px" }}>
            <img
              src={
                row && row?.key === "esimgo"
                  ? esimgoSvg
                  : row?.key === "esimVault"
                    ? esimVaultSvg
                    : airaloSvg
              }
              alt="offer-icon"
              width={40}
            />
          </div>
          <span className="ms-2">{row && row?.key}</span>
        </div>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: <span className="dataTable-text">{t("table.packageName")}</span>,
      key: "name",
      selector: (row) => row.package,
      cell: (row) => (
        <span
          className="text-start column-text"

        >
          {row.name && row.name.length > 35
            ? `${row.name.slice(0, 35)}...`
            : row.name}
        </span>
      ),
      sortable: true,
      width: "220px",
    },
    {
      name: <span className="dataTable-text">{t("table.validCountry")}</span>,
      key: "countries",
      selector: (row) => <span className="text-start column-text">{row?.countries}</span>,
      sortable: true,
      width: "260px",
    },
    {
      name: <span className="dataTable-text">{t("table.validDays")}</span>,
      key: "validity",
      selector: (row) => (
        <span className="column-text">
          {row?.validity} {t("home.part21")}
        </span>
      ),
      sortable: true,
      width: currentLocalization == "in" || currentLocalization == "fr" ? "190px": "160px",
    },
    {
      name: <span className="dataTable-text">{t("table.Data")}</span>,
      key: "data",
      selector: (row) => (
        <span className="column-text">
          {row?.data.length < 0 ? row?.data.toFixed(2) : row?.data.toFixed(2)} {t("home.part22")}
        </span>
      ),
      sortable: true,
      width: "120px",
    }, {
      name: <span className="dataTable-text">{t("table.ActualPrice")}</span>,
      key: "price",
      selector: (row) => <span className="column-text">${Number(row?.packagePrice).toFixed(2)}</span>,
      sortable: true,
      width: currentLocalization == "in" || currentLocalization == "fr" ? "220px": "190px",
    },
    {
      name: <span className="dataTable-text">{t("table.Price")}</span>,
      key: "price",
      selector: (row) => <span className="column-text">${Number(row?.price).toFixed(2)}</span>,
      sortable: true,
      width: "140px",
    }, {
      name: <span className="dataTable-text">{t("table.margin")}</span>,
      key: "price",
      selector: (row) => <span className="column-text">{Number(row?.margin ?? 30)}%</span>,
      sortable: true,
      width: "160px",
    },
    {
      name: <span className="dataTable-text">{t("table.Status")}</span>,
      selector: (row) => (
        <span className="column-text">
          <DatatableMenu onEdit={handleEdit} row={row} selectedRows={selectedRows} />
        </span>
      ),
      width: "120px",
    },
  ];
  const getPackages = async (
    page,
    perPage,
    search,
    newSortField,
    newSortOrder
  ) => {
    try {
      let lang;
       if (currentLocalization.startsWith("en-")) {
        lang = "en";
      } else {
        lang = currentLocalization;
      }
      const response = await getAllSimPackage({
        lang,
        page,
        perPage,
        search,
        sortBy: newSortField,
        sortByOrder: newSortOrder,
      });
      const { data, totalPages } = response;
      setPackages(data);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (err) {
      if (err.response?.status == 401) {
        const id = toast.error(t("toastsMessage.part4"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        localStorage.clear();
        navigate("/log-in");
      } else {
        console.log("err", err);
        const id = toast.error(err.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleChangePage = (page) => {
    setCurrentPage(page);
    getPackages(page, 20, search, "createdAt", sortOrder);
  };
  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setToggleClearRows(true)
    getPackages(currentPage, newPerPage, search, "createdAt", sortOrder);
  };
  const handleEdit = (row) => {
    setSelectedRows([row]);
    setModalShow(true);
  };
  const handleSort = async (column, direction) => {
    const newSortOrder = direction === "asc" ? "desc" : "asc";
    const newSortField = column.key;
    setSortOrder(newSortOrder);
    getPackages(currentPage, perPage, search, newSortField, newSortOrder);
  };

  const handleSelectedRowsChange = (rows) => {
    if (rows.allSelected) {
      setSelectedRows(packages);
    } else {
      setSelectedRows(rows.selectedRows);
    }
  };
  const handleSubmit = async () => {
    try {
      if (!percentage) {
        setError(true);
        return;
      }
      setAddLoading(true);
      const packageIdsArray = selectedRows?.map((row) => row.package_id);
      const object = {
        packageIds: packageIdsArray,
        percentage: percentage,
      };
      const response = await updatePackage(object);
      if (response) {
        getPackages(1, 20, search, "createdAt", sortOrder);
        setModalShow(false);
        const id = toast.success(response.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        setPercentage("")
        setToggleClearRows(true)
        setSelectedRows([])
      }
    } catch (e) {
      console.log("e", e);
    } finally {
      setAddLoading(false);
    }
  };
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const getData = setTimeout(() => {
      getPackages(1, 20, search, "createdAt", sortOrder);
    }, 1000);
    return () => clearTimeout(getData);
  }, [search]);
  useEffect(() => {
    getPackages(1, 20, search, "createdAt", sortOrder);
    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
    }
  }, [currentLocalization]);

  return (
    <Sidebar>
      <Container>
        <Row>
          <Col>
            <Row className="d-flex align-items-center justify-content-between">
              <Col md={3} xs={12}>
                <div
                  className={
                    currentLocalization == "ar" ? "text-end" : "text-start"
                  }
                >
                  <h2 className="package-h2">{t("dataUsage.myPackage")}</h2>
                </div>
              </Col>
              <Col md={9} xs={12} className="mt-4 ">
                <Row className="mt-2 d-flex align-items-center justify-content-end">
                  <Col md={2} xs={12} className=" text-end">
                    <button
                      className={selectedRows?.length < 1 ? "edit-all" : "edit-all-active"}
                      onClick={() => setModalShow(true)}
                      disabled={selectedRows?.length < 1 ? true : false}
                    >
                      Edit All
                    </button>
                  </Col>
                  <Col md={5} xs={12}>
                    <div className="search-input d-flex align-items-center">
                      <input
                        type="text"
                        name="search"
                        placeholder="Search Package"
                        className={`${currentLocalization == "ar"
                          ? "offdK-right-search ps-3"
                          : "offdK-search ps-3"
                          }`}
                        onChange={(e) => setSearch(e.target.value)}
                        value={search}
                      />
                      <FaSearch size={20} color="#215B5B" />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="mt-4">
              <ListingDataTables
                columns={columns}
                data={packages}
                loading={loading}
                totalRows={totalPages * perPage}
                perPage={perPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handlePerPageChange}
                handleSort={handleSort}
                handleSelectedRowsChange={handleSelectedRowsChange}
                toggledClearRows={toggledClearRows}
              />
            </div>
          </Col>
        </Row>
        <Modals
          modalShow={modalShow}
          setModalShow={setModalShow}
          handleSubmit={handleSubmit}
          setPercentage={setPercentage}
          percentage={percentage}
          error={error}
          addLoading={addLoading}
          setError={setError}
        />
      </Container>
    </Sidebar>
  );
};

export default MyPackage;
