import axios from "axios";

const currencyExchange = ()=>{
    return new Promise((resolve, reject) => {
        const URL = `https://api.exchangerate-api.com/v4/latest/USD`;
        axios
          .get(URL)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
}

export default currencyExchange;