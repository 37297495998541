import React, { createContext, useEffect, useState } from "react";
import getPackagesDetails from "../services/packages/getPackages";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { countryCurrency } from "../constants/currency";
import { CURRENCY } from "../constants/localstorageKeys";
export const AuthUserContext = createContext();

const AuthState = (props) => {
  const [currentLocalization, setCurrentLocalization] = useState("");
  const [selectData, setSelectData] = useState("");
  const [selectDay, setSelectDays] = useState("");
  const [selectPrice, setSelectPrice] = useState("");
  const [packageType, setPackageType] = useState("");
  const [getCountrys, setGetCountrys] = useState([]);
  const [linkCopy, setLinkCopy] = useState("");
  const [countryCodes, setCountryCode] = useState('')
  const [topUpLink,setTopUpLink] = useState("")
  const [toastId, setToastId] = useState(null);
  const [user, setUser] = useState([]);
  const fetchUserLocation = async () => {
    try {
      const response = await axios.get('https://ipinfo.io/json');
      const { country } = response.data;
      const getCountryCurrency = countryCurrency.find((code)=> code.country_code === country)
      localStorage.setItem(CURRENCY, getCountryCurrency.currency_code);
      setCountryCode(getCountryCurrency.currency_code)
    } catch (error) {
      console.error('Error fetching user location:', error);
    }
  };
  useEffect(() => {
    const storedLocalization = localStorage.getItem("i18nextLng");
    if (storedLocalization) {
      setCurrentLocalization(storedLocalization);
    }
    fetchUserLocation()
  }, []);
  return (
    <AuthUserContext.Provider
      value={{
        currentLocalization,
        setCurrentLocalization,
        setSelectData,
        selectData,
        setSelectDays,
        selectDay,
        setSelectPrice,
        selectPrice,
        setPackageType,
        packageType,
        setGetCountrys,
        getCountrys,
        setUser,
        user,
        setLinkCopy,
        linkCopy,
        countryCodes,
        setCountryCode,
        setTopUpLink,
        topUpLink,
        setToastId,
        toastId
      }}
    >
      {props.children}
    </AuthUserContext.Provider>
  );
};
export default AuthState;
