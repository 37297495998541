import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Menus from "../../component/Menu";
import { useTranslation } from "react-i18next";
import { LiaGreaterThanSolid } from "react-icons/lia";
import CurrencyNameSelect from "../../component/CurrencyNameSelect";
import Footer from "../../component/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import getEsimLists from "../../services/api/eSim/getEsimDetails";
import { AuthUserContext } from "../../context/context";
import Spinner from "react-bootstrap/esm/Spinner";
import { FaLessThan } from "react-icons/fa6";
import OfferCardFilter from "../../component/OfferCardFilter";
import Navbars from "../../component/Navbar/Navbar";
import getCountrys from "../../services/api/getCountry";
import currencyExchange from "../../services/api/currencyyExchange";
import {
  countryCode,
  countryCurrency,
  currencySymbols,
} from "../../constants/currency";
import SortSelect from "../../component/SortSelect";
import {
  COUNTRY_CURRENCY,
  CURRENCY,
  USER_DETAILS,
} from "../../constants/localstorageKeys";
import { countryList } from "../../constants/countryList";
import RegisterOffers from "../../component/RegisterOffers";
const CountryFilter = ({}) => {
  const {
    currentLocalization,
    setSelectData,
    selectData,
    setSelectDays,
    selectDay,
    setSelectPrice,
    selectPrice,
    setPackageType,
    packageType,
    user,
    setUser,
    setToastId
  } = useContext(AuthUserContext);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const searchParams = new URLSearchParams(location.search);
  const country = searchParams.get("country");
  const { t } = useTranslation();
  // const countryCurrency = localStorage.getItem(COUNTRY_CURRENCY);
  const currency = localStorage.getItem(CURRENCY);
  const [eSimDetails, setEsimDetails] = useState([]);
  const [filterDetails, setFilterDetails] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [simRecordLength, setSimRecordLength] = useState(0);
  const [getCountryss, setGetCountrys] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [priceSorting, setPriceSorting] = useState("all");
  const [initialized, setInitialized] = useState(true);

  const handleCurrencyChange = ({ exchangeRate, currency }) => {
    setExchangeRate(exchangeRate);
    setCurrencySymbol(currency);
  };
  const getCountryDetails = async () => {
    try {
      const countryCode =
        selectedCountry && selectedCountry?.length > 0 && selectedCountry;
      countryList?.forEach((element) => {
        if (element?.cca2 == countryCode) {
          setGetCountrys(element?.name?.common);
        }
      });
    } catch (e) {
      console.log("e", e);
    } finally {
      setLoading(false);
    }
  };
  const globallGetEsim = async (days, price, data, region, lang) => {
    try {
      setLoading(true);
      let countryCode =
        selectedCountry && selectedCountry?.length > 0 ? selectedCountry : "SA";
      if (!Array.isArray(countryCode)) {
        countryCode = [countryCode];
      }
      const countriesParam = countryCode
        .map((country) => `countries[]=${country}`)
        .join("&");
      let langs;
      if (lang === "en-US") {
        langs = "en";
      } else {
        langs = lang;
      }
      const response = await getEsimLists(
        days,
        price,
        data,
        countriesParam,
        region,
        langs
      );
      let fetchedData = response?.data || [];
      if (priceSorting === "price") {
        let priceSort = fetchedData.sort((a, b) => a.price - b.price);
        setFilterDetails(priceSort);
      } else if (priceSorting === "data") {
        let dataSort = fetchedData.sort((a, b) => b.data - a.data);
        setFilterDetails(dataSort);
      } else if (priceSorting === "validity") {
        let validitySort = fetchedData.sort((a, b) => b.validity - a.validity);
        setFilterDetails(validitySort);
      } else {
        setFilterDetails(fetchedData);
      }
      setFilterDetails(fetchedData);
      setEsimDetails(fetchedData);
      setSimRecordLength(fetchedData.length);
      console.log("selectData", selectData, selectDay, selectPrice);
      applyFilters(selectData, selectDay, selectPrice, fetchedData);
    } catch (err) {
      const id = toast.error(err.response?.data?.message || err.message, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true
      });
      setToastId(id);
    } finally {
      setLoading(false);
    }
  };
  const getEsimList = async (days, price, data, region, lang) => {
    try {
      setLoading(true);
      setSelectData("");
      setSelectPrice("");
      setSelectDays("");
      setPackageType("")
      let countryCode =
        selectedCountry && selectedCountry?.length > 0 ? selectedCountry : "SA";
      if (!Array.isArray(countryCode)) {
        countryCode = [countryCode];
      }
      const countriesParam = countryCode
        .map((country) => `countries[]=${country}`)
        .join("&");
      let langs;
      if (lang === "en-US") {
        langs = "en";
      } else {
        langs = lang;
      }
      const response = await getEsimLists(
        days,
        price,
        data,
        countriesParam,
        region,
        langs
      );
      let fetchedData = response?.data || [];
      if (priceSorting === "price") {
        let priceSort = fetchedData.sort((a, b) => a.price - b.price);
        setFilterDetails(priceSort);
      } else if (priceSorting === "data") {
        let dataSort = fetchedData.sort((a, b) => b.data - a.data);
        setFilterDetails(dataSort);
      } else if (priceSorting === "validity") {
        let validitySort = fetchedData.sort((a, b) => b.validity - a.validity);
        setFilterDetails(validitySort);
      } else {
        setFilterDetails(fetchedData);
      }
      setFilterDetails(fetchedData);
      setEsimDetails(fetchedData);
      setSimRecordLength(fetchedData.length);
      // console.log("selectData",selectData, selectDay, selectPrice);
      // applyFilters(selectData, selectDay, selectPrice, fetchedData);
    } catch (err) {
      const id = toast.error(err.response?.data?.message || err.message, {
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true
      });
      setToastId(id);
    } finally {
      setLoading(false);
    }
  };

  const handleDataFilter = (data) => {
    try {
      setSelectData(data);
      applyFilters(data, selectDay, selectPrice, eSimDetails);
    } catch (e) {
      console.log("Error processing", e);
    }
  };
  const handleDayFilter = (day) => {
    try {
      setSelectDays(day);
      applyFilters(selectData, day, selectPrice, eSimDetails);
    } catch (e) {
      console.log("Error processing", e);
    }
  };
  const handlePriceFilter = (price) => {
    try {
      setSelectPrice(price);
      applyFilters(selectData, selectDay, price, eSimDetails);
    } catch (e) {
      console.log("Error processing", e);
    }
  };
  const applyFilters = (data, day, price, eSimDetails) => {
    let filteredData = eSimDetails;
    if (data !== "") {
      filteredData = filteredData.filter((item) => item.data >= data);
    }
    if (day !== "") {
      filteredData = filteredData.filter((item) => item.validity >= day);
    }
    if (price !== "") {
      filteredData = filteredData.filter((item) => item.price <= price);
    }

    setSimRecordLength(filteredData?.length);
    setFilterDetails(filteredData);
  };
  const handlePackageType = async (data) => {
    setPackageType(data);
    if (data == "Global") {
      globallGetEsim("", "", "", "Global", currentLocalization);
    } else if (data == "countryName") {
      globallGetEsim("", "", country, getCountryss, currentLocalization);
    } else {
      globallGetEsim("", "", country, "", currentLocalization);
    }
  };
  const handleAllFilter = () => {
    try {
      setSelectData("");
      setSelectPrice("");
      setSelectDays("");
      if (packageType === "Global" || packageType === "countryName") {
        getEsimList("", "", country, "", currentLocalization);
      }
      setPackageType("");

      setSimRecordLength(eSimDetails?.length);
      setFilterDetails(eSimDetails);
    } catch (e) {
      console.log("Error processing", e);
    }
  };
  const handleSorting = () => {
    if (priceSorting === "price") {
      const sortedESimDetails = [...eSimDetails].sort(
        (a, b) => a.price - b.price
      );
      setFilterDetails(sortedESimDetails);
    } else if (priceSorting === "data") {
      const sortedESimDetails = [...eSimDetails].sort(
        (a, b) => b.data - a.data
      );
      setFilterDetails(sortedESimDetails);
    } else if (priceSorting === "validity") {
      const sortedESimDetails = [...eSimDetails].sort(
        (a, b) => b.validity - a.validity
      );
      setFilterDetails(sortedESimDetails);
    } else {
      setFilterDetails(eSimDetails);
    }
  };

  const currencyCHange = async () => {
    try {
      if (currency) {
        const getCountryCurrency = countryCurrency.find(
          (code) => code.currency_code === currency
        );
        const country = countryCode.find(
          (country) => country.countryName === getCountryCurrency.country_code
        );
        const newCurrency = country ? country.countryCurrency : "USD";
        const currencyCode =
          currencySymbols.find((symbol) => symbol.Code === newCurrency)
            ?.Symbol ?? currency;
        setCurrencySymbol(currencyCode);
        setSelectedCurrency(newCurrency);
        const response = await currencyExchange();
        const exchangeRate = response.rates[newCurrency];
        setExchangeRate(exchangeRate);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  useEffect(() => {
    setSelectedCountry([country || "SA"]);
    if (
      selectedCountry?.length <= 0 ||
      selectedCountry == undefined ||
      !country
    ) {
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set("country", country || "SA");
      const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
    }

    const userDetails = JSON.parse(localStorage.getItem(USER_DETAILS));
    if (userDetails) {
      setUser(userDetails);
      setInitialized(false);
    } else {
      setInitialized(false);
    }
  }, []);
  useEffect(() => {
    getCountryDetails();
    if (selectedCountry.length > 0) {
      getEsimList("", "", "", "", currentLocalization);
    }
  }, [currentLocalization, selectedCountry]);
  useEffect(() => {
    currencyCHange();
  }, []);
  useEffect(() => {
    handleSorting();
  }, [priceSorting]);
  console.log("getCountryss", getCountryss);
  
  return (
    <div>
      <Navbars />
      <Menus
        text={t("countryFilter.part1")}
        textGb={t("countryFilter.part2")}
        textWork={t("home.part16")}
        showSelect={true}
        setSelectedCountry={setSelectedCountry}
        country={country || selectedCountry[0]}
        selectedCountry={selectedCountry}
        getCountrys={t(`countryName.${getCountryss}`)}
        status="country-filter"
        getCountryss={getCountryss}
      />
      <section className="offers-image">
        <Container>
          <Row
            className={`d-flex justify-content-center ${
              currentLocalization == "ar" && "me-2"
            }`}
          >
            <Col
              className={`text-start mt-4 ps-md-0 ${
                currentLocalization == "ar" && " d-flex flex-row-reverse"
              }`}
              xs={12}
              md={12}
            >
              <span
                className="country-name-span"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              >
                {t("navbar.home")}
              </span>
              {currentLocalization == "ar" ? (
                <span className="country-name-span me-1 ms-1">
                  <FaLessThan size={11} />
                </span>
              ) : (
                <span className="country-name-span ms-1">
                  <LiaGreaterThanSolid size={11} />
                </span>
              )}
              <span className="country-name-span ">
                <b>
                  &nbsp;{t("home.part24")} {t(`countryName.${getCountryss}`)}
                </b>
              </span>
            </Col>
            <p
              className={`discovery-p text-start p-md-0 mt-3 ${
                currentLocalization == "ar" && "d-flex flex-row-reverse"
              }`}
            >
              {t("countryFilter.part3")} {t(`countryName.${getCountryss}`)}
              &nbsp;{t("countryFilter.part30")}
            </p>
          </Row>
          <Row
            className={
              currentLocalization == "ar"
                ? "pb-4 me-2 show-banner"
                : "pb-4 me-3"
            }
          >
            <Col className="text-start p-0 ">
              {!initialized && (
                <>{user.length !== 0 ? <></> : <RegisterOffers />}</>
              )}
            </Col>
          </Row>

          <Row className={`p-0 mb-0 ${currentLocalization == "ar" && "me-2"}`} >
            <Col
              xs={12}
              className={`d-flex flex-row justify-content-between align-items-center p-md-0 ${
                currentLocalization == "ar" && "flex-row-reverse"
              }`}
            >
              <span className="show-product-p">
                {t("countryFilter.part4")} {!loading && simRecordLength}
                &nbsp;{t("countryFilter.part")}
              </span>
              <div
                className={`d-flex ${
                  currentLocalization == "ar"
                    ? "select-box-padding-right"
                    : "select-box-padding"
                }`}
              >
                <CurrencyNameSelect
                  onCurrencyChange={handleCurrencyChange}
                  selectedCurrency={selectedCurrency}
                  setSelectedCurrency={setSelectedCurrency}
                />
                <div className="ms-3">
                  <SortSelect
                    setPriceSorting={setPriceSorting}
                    setSelectData={setSelectData}
                    setSelectDays={setSelectDays}
                    setSelectPrice={setSelectPrice}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row
            className={`d-flex flex-row justify-content-center  ${
              currentLocalization == "ar" && "flex-row-reverse"
            }`}
          >
            <Col
              lg={3}
              md={3}
              xs={11}
              className={`text-start ps-0 pe-3  mb-4 mt-4 ${
                currentLocalization == "ar" && "text-end"
              }`}
            >
              <div className="filter-box p-3">
                <div
                  className={`d-flex align-items-center justify-content-between ${
                    currentLocalization == "ar" && "flex-row-reverse"
                  }`}
                >
                  <h5 className="filters-h5">{t("countryFilter.part5")}</h5>
                  <p className="clear-filter" onClick={() => handleAllFilter()}>
                    {t("countryFilter.part29")}
                  </p>
                </div>

                <div className="mt-3 ">
                  <span className="package-type-span">
                    {t("countryFilter.part6")}
                  </span>
                  <div
                    className={`mt-2 ${
                      currentLocalization == "ar" &&
                      "d-flex flex-row-reverse flex-wrap"
                    }`}
                  >
                    <button
                      className={
                        !packageType ? "filter-button-active" : "filter-button"
                      }
                      onClick={() => handlePackageType("")}
                      disabled={loading}
                    >
                      {t("countryFilter.part7")}
                    </button>
                    <button
                      className={
                        packageType == "countryName"
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePackageType("countryName")}
                      disabled={loading}
                    >
                      {t("countryFilter.part8")}
                    </button>
                    <button
                      className={
                        packageType == "Global"
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePackageType("Global")}
                      disabled={loading}
                    >
                      {t("countryFilter.part9")}
                    </button>
                  </div>
                </div>
                <Row className="d-flex justify-content-center mt-3">
                  <Col
                    md={11}
                    xs={11}
                    style={{ borderTop: "1.5px solid #D9D9D9" }}
                  ></Col>
                </Row>
                <div className="mt-3">
                  <span className="package-type-span">
                    {t("countryFilter.part10")}
                  </span>
                  <div
                    className={`mt-2 ${
                      currentLocalization == "ar" &&
                      "d-flex flex-row-reverse flex-wrap"
                    }`}
                  >
                    <button
                      className={
                        !selectData ? "filter-button-active" : "filter-button"
                      }
                      onClick={() => handleDataFilter("")}
                      disabled={loading}
                    >
                      {t("countryFilter.part7")}
                    </button>
                    <button
                      className={
                        selectData == 3
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(3)}
                      disabled={loading}
                    >
                      {t("countryFilter.part11")}
                    </button>
                    <button
                      className={
                        selectData == 5
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(5)}
                      disabled={loading}
                    >
                      {t("countryFilter.part12")}
                    </button>
                    <button
                      className={
                        selectData == 8
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(8)}
                      disabled={loading}
                    >
                      {t("countryFilter.part13")}
                    </button>
                    <button
                      className={
                        selectData == 12
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(12)}
                      disabled={loading}
                    >
                      {t("countryFilter.part14")}
                    </button>
                    <button
                      className={
                        selectData == 20
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(20)}
                      disabled={loading}
                    >
                      {t("countryFilter.part15")}
                    </button>
                    <button
                      className={
                        selectData == 50
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDataFilter(50)}
                      disabled={loading}
                    >
                      {t("countryFilter.part16")}
                    </button>
                  </div>
                </div>
                <Row className="d-flex justify-content-center mt-3">
                  <Col
                    md={11}
                    xs={11}
                    style={{ borderTop: "1.5px solid #D9D9D9" }}
                  ></Col>
                </Row>
                <div className="mt-3">
                  <span className="package-type-span">
                    {t("countryFilter.part17")}
                  </span>
                  <div
                    className={`mt-2 ${
                      currentLocalization == "ar" &&
                      "d-flex flex-row-reverse flex-wrap"
                    }`}
                  >
                    <button
                      className={
                        selectDay == ""
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter("")}
                      disabled={loading}
                    >
                      {t("countryFilter.part7")}
                    </button>
                    <button
                      className={
                        selectDay == 7
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter(7)}
                      disabled={loading}
                    >
                      {t("countryFilter.part18")}
                    </button>
                    <button
                      className={
                        selectDay == 14
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter(14)}
                      disabled={loading}
                    >
                      {t("countryFilter.part19")}
                    </button>
                    <button
                      className={
                        selectDay == 30
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter(30)}
                      disabled={loading}
                    >
                      {t("countryFilter.part20")}
                    </button>
                    <button
                      className={
                        selectDay == 60
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter(60)}
                      disabled={loading}
                    >
                      {t("countryFilter.part21")}
                    </button>
                    <button
                      className={
                        selectDay == 90
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handleDayFilter(90)}
                      disabled={loading}
                    >
                      {t("countryFilter.part22")}
                    </button>
                  </div>
                </div>
                <Row className="d-flex justify-content-center mt-3">
                  <Col
                    md={11}
                    xs={11}
                    style={{ borderTop: "1.5px solid #D9D9D9" }}
                  ></Col>
                </Row>
                <div className="mt-3">
                  <span className="package-type-span">
                    {t("countryFilter.part23")}
                  </span>
                  <div
                    className={`mt-2 ${
                      currentLocalization == "ar" &&
                      "d-flex flex-row-reverse flex-wrap"
                    }`}
                  >
                    <button
                      className={
                        selectPrice == ""
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter("")}
                      disabled={loading}
                    >
                      {t("countryFilter.part7")}
                    </button>
                    <button
                      className={
                        selectPrice == 10
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter(10)}
                      disabled={loading}
                    >
                      {t("countryFilter.part24")}
                    </button>
                    <button
                      className={
                        selectPrice == 20
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter(20)}
                      disabled={loading}
                    >
                      {t("countryFilter.part25")}
                    </button>
                    <button
                      className={
                        selectPrice == 30
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter(30)}
                      disabled={loading}
                    >
                      {t("countryFilter.part26")}
                    </button>
                    <button
                      className={
                        selectPrice == 40
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter(40)}
                      disabled={loading}
                    >
                      {t("countryFilter.part27")}
                    </button>
                    <button
                      className={
                        selectPrice == 50
                          ? "filter-button-active"
                          : "filter-button"
                      }
                      onClick={() => handlePriceFilter(50)}
                      disabled={loading}
                    >
                      {t("countryFilter.part28")}
                    </button>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={9} xs={11} className="">
              {loading ? (
                <div
                  className="w-100 d-flex justify-content-center align-items-center"
                  style={{ paddingTop: "150px", paddingBottom: "150px" }}
                >
                  <Spinner animation="border" variant="dark" />
                </div>
              ) : (
                <Row className="mt-3">
                  <Col
                    xs={12}
                    className={`d-flex flex-wrap p-0 ${
                      currentLocalization == "ar"
                        ? "justify-content-start"
                        : "justify-content-start"
                    }`}
                  >
                    {filterDetails?.length > 0 ? (
                      <>
                        {filterDetails?.map((item, index) => {
                          return (
                            <OfferCardFilter
                              item={item}
                              key={index}
                              index={index}
                              offers={eSimDetails}
                              exchangeRate={exchangeRate}
                              currencySymbol={currencySymbol}
                              getCountryss={getCountryss}
                              user={user}
                              packageType={packageType}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <div className="w-100 pt-5 pb-5">
                        <span>{t("home.part28")}</span>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          <Row className="d-flex mt-5 justify-content-center">
            <Col className="m-0 p-0" md={12} xs={11}>
              <Footer />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default CountryFilter;
