import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RegisterSidebar from "../../component/RegisterSidebar";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import Input from "../../component/Input";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import PasswordInput from "../../component/PasswordInput";
import { AuthUserContext } from "../../context/context";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/esm/Spinner";
import login from "../../services/auth/login";
import { toast } from "react-toastify";
import authLogo from "../../assets/images/auth-logo.svg";
import {
  AUTH_TOKEN_KEY,
  SIM_PURCHASE,
  USER_DETAILS,
} from "../../constants/localstorageKeys";
import LanguageSelect from "../../component/LanguageSelect";
import getPackagesDetails from "../../services/packages/getPackages";

const Login = ({ setEventTriggered }) => {
  const { t } = useTranslation();
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const {
    currentLocalization,
    setUser,
    linkCopy,
    setLinkCopy,
    topUpLink,
    setTopUpLink,
    setToastId,
    toastId,
  } = useContext(AuthUserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!formData.email || !formData.password) {
        setValidated(true);
        return;
      }
      let lang;
      if (currentLocalization.startsWith("en-")) {
        lang = "en";
      } else {
        lang = currentLocalization;
      }
      setValidated(false);
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        setEmailValidation(true);
        return;
      }
      setEmailValidation(false);
      if (formData.password.length < 6) {
        setPasswordValidation(true);
        return;
      }
      setLoading(true);
      setPasswordValidation(false);
      const object = {
        email: formData.email,
        password: formData.password,
        lang: lang,
      };
      const response = await login(object);
      if (response) {
        const userDetails = {
          id: response?.data?.id,
          email: response?.data?.email,
          name: response?.data?.name,
          role: response?.data?.role,
          phoneNo: response?.data?.phone_no,
          otpVerified: response?.data?.otpVerified,
          countries: response?.data?.countries,
          referralCode: response?.data?.referralCode,
          parent_id: response?.data?.parent_id ?? null,
        };
        localStorage.setItem(USER_DETAILS, JSON.stringify(userDetails));
        localStorage.setItem(SIM_PURCHASE, response?.data?.has_topup);
        setUser(userDetails);
        localStorage.setItem(AUTH_TOKEN_KEY, response?.data?.token);
        
       
        if (linkCopy) {
          navigate(`/offer-detail${linkCopy}`);
        } else if (topUpLink) {
          navigate("/top-up");
        } else if (response?.data?.role == "user") {
          const id = toast.success(response?.message, {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            pauseOnHover: true,
          });
          setToastId(id);
          navigate("/data-usage");
        } else if (response?.data?.role == "admin") {
          const id = toast.success(response?.message, {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            pauseOnHover: true,
          });
          setToastId(id);
          navigate("/users-listing");
        }
        window.scroll(0, 0);
      }
    } catch (err) {
      if (err.response?.status == 404) {
        // setEventTriggered(true)
        const id = toast.error(err.response?.data?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
        setToastId(id);
      } else if (err.response?.status == 400) {
        navigate("/otp-verification", {
          state: { email: err.response?.data?.data?.email },
        });
        const id = toast.error(err.response?.data?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
        setToastId(id);
      } else {
        // setEventTriggered(true)
        const id = toast.error(err?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true,
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleTogglePassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name == "password") {
      setPasswordValidation(false);
    }
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      if (toastId) setEventTriggered(true);
      event.preventDefault();
      handleSubmit(event);
    }
  };
  return (
    <Container fluid className="main-login-image">
      <Row className="row-login d-flex justify-content-center">
        <Col xs={11} md={6} className="loginImage p-0 ">
          <RegisterSidebar />
        </Col>
        <Col xs={12} md={6} className=" text-start login-side">
          <Row className="d-flex justify-content-center align-items-center pt-5">
            <Col
              md={9}
              className={`pt-0 p-md-0 p-4 d-flex align-items-center justify-content-between ${
                currentLocalization == "ar" && "flex-row-reverse"
              }`}
            >
              <Link
                to="/"
                onClick={() => {
                  setLinkCopy("");
                  setTopUpLink("");
                }}
              >
                <img src={authLogo} alt="" />
              </Link>
              <LanguageSelect />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center pb-5 h-75">
            <Col md={9} className="pt-0 p-md-0 p-4">
              <div
                style={{ marginTop: "1px" }}
                className={`p-0 ${
                  currentLocalization == "ar" ? "text-end" : "text-start"
                }`}
              >
                <h2 className="login-welcome">{t("login.part1")}</h2>
                <span className="login-information">{t("login.part2")}</span>
                <Form
                  method="post"
                  onKeyDown={handleKeyDown}
                  onSubmit={handleSubmit}
                  className="mt-4"
                >
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom01"
                    className="d-flex flex-column"
                  >
                    <Input
                      labelClassName="label"
                      labelText={t("login.part3")}
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder={t("login.part4")}
                      className={
                        currentLocalization == "ar"
                          ? "login-input-right"
                          : "login-input-left"
                      }
                    />
                    <div
                      className={
                        currentLocalization == "ar"
                          ? "text-end mt-2"
                          : "text-start mt-2"
                      }
                    >
                      {validated && !formData.email ? (
                        <span className="text-danger">
                          {t("validation.emailRequired")}
                        </span>
                      ) : (
                        emailValidation && (
                          <span className="text-danger">
                            {t("validation.invalidEmail")}
                          </span>
                        )
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    className="mt-1"
                    controlId="validationCustom02"
                  >
                    <PasswordInput
                      handleTogglePassword={handleTogglePassword}
                      label={t("login.part5")}
                      showPassword={showPassword}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className={
                        currentLocalization == "ar"
                          ? "login-input-password-right"
                          : "login-input-password-left"
                      }
                      labelClassName="label"
                      placeholder={t("login.part6")}
                      currentLocalization={currentLocalization}
                    />
                    <div
                      className={
                        currentLocalization == "ar"
                          ? "text-end mt-2"
                          : "text-start mt-2"
                      }
                    >
                      {validated && !formData.password ? (
                        <span className="text-danger">
                          {t("validation.passwordRequired")}
                        </span>
                      ) : (
                        passwordValidation && (
                          <span className="text-danger">
                            {t("validation.passwordLength")}
                          </span>
                        )
                      )}
                    </div>
                  </Form.Group>
                  <div
                    className={`d-flex align-items-center justify-content-end ${
                      currentLocalization == "ar"
                        ? "flex-row-reverse"
                        : "flex-row"
                    }`}
                    style={{ marginTop: "15px" }}
                  >
                    <div>
                      <Link
                        to="/reset-password"
                        style={{ textDecoration: "none" }}
                        onClick={() => window.scroll(0, 0)}
                      >
                        <span className="forget-password">
                          {t("login.part8")}
                        </span>
                      </Link>
                    </div>
                  </div>
                  <Row style={{ marginTop: "25px" }}>
                    <Col>
                      <div className="d-grid gap-2 ">
                        <button
                          className={
                            loading
                              ? "btn-login-main-disabled"
                              : "btn-login-main"
                          }
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              {currentLocalization == "ar" ? (
                                <>
                                  <span className="me-2">
                                    ... {t("login.part16")}
                                  </span>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              ) : (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <span className="ms-2">
                                    {t("login.part16")} ...
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {currentLocalization == "ar" ? (
                                <>
                                  <GrFormPreviousLink
                                    size={27}
                                    className="me-1"
                                  />
                                  {t("login.part9")}
                                </>
                              ) : (
                                <>
                                  {t("login.part9")}
                                  <GrFormNextLink size={27} className="ms-1" />
                                </>
                              )}
                            </>
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <div className="text-center" style={{ marginTop: "25px" }}>
                  <span className="dont-login">{t("login.part10")}</span>
                  <Link
                    to="/sign-up"
                    style={{ textDecoration: "none" }}
                    onClick={() => window.scroll(0, 0)}
                  >
                    <span className="dont-login-today">
                      &nbsp;{t("login.part11")}
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
