import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RegisterSidebar from "../../component/RegisterSidebar";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import PasswordInput from "../../component/PasswordInput";
import { AuthUserContext } from "../../context/context";
import Spinner from "react-bootstrap/esm/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import updatePassword from "../../services/auth/updatePassword";
import { toast } from "react-toastify";
import authLogo from "../../assets/images/auth-logo.svg";
import LanguageSelect from "../../component/LanguageSelect";
const NewPassword = ({setEventTriggered}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentLocalization, setToastId, toastId } = useContext(AuthUserContext);
  const [validated, setValidated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordCheck, setConfirmPasswordCheck] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [decodedAuthToken, setDecodedAuthToken] = useState();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const getToken = queryParams.get("token");
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (!password || !confirmPassword) {
        setValidated(true);
        return;
      }
      if (password.length < 6) {
        setPasswordValidation(true);
        return;
      }
      setPasswordValidation(false);
      if (password !== confirmPassword) {
        setPasswordMatch(true);
        return;
      }
      let lang;
       if (currentLocalization.startsWith("en-")) {
        lang = "en";
      } else {
        lang = currentLocalization;
      }
      setPasswordMatch(false);
      setLoading(true);
      const object = {
        id: decodedAuthToken?.userId,
        newPassword: password,
        lang: lang,
      };
      let response = await updatePassword(object);
      if (response) {
        const id = toast.success(response?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
        window.scroll(0, 0);
        navigate("/log-in");
      }
    } catch (err) {
      if (err.response?.status == 404) {
        const id = toast.error(err.response?.data?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      } else if (err.response?.status == 400) {
        const id = toast.error(err.response?.data?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      } else {
        const id = toast.error(err?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleTogglePassword = (event) => {
    event.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = (event) => {
    event.preventDefault();
    setConfirmPasswordCheck(!confirmPasswordCheck);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      if (toastId) setEventTriggered(true);
      event.preventDefault();
      handleSubmit(event);
    }
  };
  const checkToken = () => {
    if (getToken) {
      setDecodedAuthToken(jwtDecode(getToken));
    } else {
      navigate("/log-in");
    }
  };
  useEffect(() => {
    checkToken();
  }, [getToken]);
  return (
    <Container fluid className="main-login-image">
      <Row className="row-login d-flex justify-content-center">
        <Col xs={11} md={6} className="loginImage p-0 ">
          <RegisterSidebar />
        </Col>
        <Col xs={12} md={6} className=" text-start login-side">
          <Row className="d-flex justify-content-center align-items-center pt-5">
            <Col
              md={9}
              className={`pt-0 p-md-0 p-4 d-flex align-items-center justify-content-between ${
                currentLocalization == "ar" && "flex-row-reverse"
              }`}
            >
              <Link to="/">
                <img src={authLogo} alt="" />
              </Link>
              <LanguageSelect />
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center pt-5 pb-5 large-screen">
            <Col md={9} className="pt-0 p-md-0 p-4">
              <div
                style={{ marginTop: "15px" }}
                className={`p-0 ${
                  currentLocalization == "ar" ? "text-end" : "text-start"
                }`}
              >
                <h2 className="login-welcome">{t("newPassword.part1")}</h2>
                <span className="login-information">
                  {t("newPassword.part2")}
                </span>
                <Form
                  method="post"
                  onKeyDown={handleKeyDown}
                  onSubmit={handleSubmit}
                  className="mt-5"
                >
                  <Form.Group
                    as={Col}
                    md="12"
                    controlId="validationCustom01"
                    className="d-flex flex-column"
                  >
                    <PasswordInput
                      handleTogglePassword={handleTogglePassword}
                      label={t("newPassword.part3")}
                      showPassword={showPassword}
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className={
                        currentLocalization == "ar"
                          ? "login-input-password-right"
                          : "login-input-password-left"
                      }
                      labelClassName="label"
                      placeholder={t("login.part5")}
                      currentLocalization={currentLocalization}
                    />
                    <div
                      className={
                        currentLocalization == "ar"
                          ? "text-end mt-2"
                          : "text-start mt-2"
                      }
                    >
                      {validated && !password ? (
                        <span className="text-danger">
                          {t("validation.newPasswordRequired")}
                        </span>
                      ) : (
                        passwordValidation && (
                          <span className="text-danger">
                            {t("validation.passwordLength")}
                          </span>
                        )
                      )}
                    </div>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    className="mt-1"
                    controlId="validationCustom02"
                  >
                    <PasswordInput
                      handleTogglePassword={handleToggleConfirmPassword}
                      label={t("newPassword.part4")}
                      showPassword={confirmPasswordCheck}
                      name="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className={
                        currentLocalization == "ar"
                          ? "login-input-password-right"
                          : "login-input-password-left"
                      }
                      labelClassName="label"
                      placeholder={t("login.part5")}
                      currentLocalization={currentLocalization}
                    />
                    <div
                      className={
                        currentLocalization == "ar"
                          ? "text-end mt-2"
                          : "text-start mt-2"
                      }
                    >
                      {validated && !confirmPassword ? (
                        <span className="text-danger">
                          {t("validation.confirmPasswordRequired")}
                        </span>
                      ) : (
                        passwordMatch && (
                          <span className="text-danger">
                            {t("validation.matchPassword")}
                          </span>
                        )
                      )}
                    </div>
                  </Form.Group>
                  <Row style={{ marginTop: "32px" }}>
                    <Col>
                      <div className="d-grid gap-2 ">
                        <button
                          className={
                            loading
                              ? "btn-login-main-disabled"
                              : "btn-login-main"
                          }
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              {currentLocalization == "ar" ? (
                                <>
                                  <span className="me-2">
                                    ... {t("login.part16")}
                                  </span>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              ) : (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <span className="ms-2">
                                    {t("login.part16")} ...
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {currentLocalization == "ar" ? (
                                <>
                                  <GrFormPreviousLink
                                    size={27}
                                    className="me-1"
                                  />
                                  {t("newPassword.part5")}
                                </>
                              ) : (
                                <>
                                  {t("newPassword.part5")}
                                  <GrFormNextLink size={27} className="ms-1" />
                                </>
                              )}
                            </>
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default NewPassword;
