import React, { useRef, useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { currencySymbols } from "../constants/currency";
import currencyExchange from "../services/api/currencyyExchange";
import { CURRENCY } from "../constants/localstorageKeys";
import { AuthUserContext } from "../context/context";

const CurrencyNameSelect = ({ onCurrencyChange,selectedCurrency,setSelectedCurrency, value }) => {
  const { t, i18n } = useTranslation();
  const { setCountryCode,currentLocalization } =
    useContext(AuthUserContext);
  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };
  const options = [
    { value: "USD", text: t("selectCurrency.part1") },
    { value: "GBP", text: t("selectCurrency.part2") },
    { value: "CAD", text: t("selectCurrency.part3") },
    { value: "IDR", text: t("selectCurrency.part4") },
    { value: "EUR", text: t("selectCurrency.part5") },
    { value: "SAR", text: t("selectCurrency.part6") },
    { value: "AED", text: t("selectCurrency.part7") },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const selectRef = useRef(null);
   
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionClick = async(option) => {
    setSelectedCurrency(option.value)
    const currency = option.value;
    localStorage.setItem(CURRENCY, currency);
    setCountryCode(currency)
    setSelectedCurrency(currency);
    setSelectedOption(option);
    setIsOpen(false);
    try {
      const response = await currencyExchange();
      const exchangeRate = response.rates[currency];
      onCurrencyChange({
        exchangeRate,
        currency:
          currencySymbols.find((symbol) => symbol.Code === currency)?.Symbol ??
          currency,
      });
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
    }
    
  };
  return (
    <div style={{ direction: getDirection() }}>
      <div className={`${value == "home" ? "select-currency-home" : value == "home-main" ? "select-currency-home-main" : "select-currency"} textField-language`} ref={selectRef}>
        <div
          className={`select-styled-currency textField-language-sorting ${isOpen ? "active" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedCurrency}
        </div>
        <ul className={`${value == "home" ? `${currentLocalization == "ar" ? "select-options-currency-home-ar": "select-options-currency-home"}` : value == "home-main" ? `${currentLocalization == "ar" ? "select-options-currency-home-ar": "select-options-currency-home"}` : `${currentLocalization == "ar" ? "select-options-currency-ar" : "select-options-currency"}`} ${isOpen ? "show" : ""}`}>
          {options.map((option) => (
            <li
              key={option.value}
              rel={option.value}
              className={
                selectedOption.value === option.value ? "is-selected" : ""
              }
              onClick={() => handleOptionClick(option)}
            >
              {option.text}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default CurrencyNameSelect;
