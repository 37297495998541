import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
const LanguageSelect = () => {
  const { i18n } = useTranslation();
  const { currentLocalization, setCurrentLocalization } =
    useContext(AuthUserContext);
  const changeLanguage = (lng) => {
    setCurrentLocalization(lng);
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <select
        className="btn-login-select textField-language"
        value={currentLocalization}
        onChange={(e) => changeLanguage(e.target.value)}
        style={{ paddingRight: "20px" }}
      >
        <option value="en" className="option-black">
          English
        </option>
        <option value="ar" className="option-black">
          Arabic
        </option>
        <option value="fr" className="option-black">
          French
        </option>
        <option value="in" className="option-black">
          Indonesian
        </option>
      </select>
    </div>
  );
};

export default LanguageSelect;
