import React, { useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { useTranslation } from "react-i18next";
import { AuthUserContext } from "../context/context";
import { useNavigate } from "react-router-dom";

const RegisterOffer = () => {
  const { t } = useTranslation();
  const { currentLocalization } = useContext(AuthUserContext);
  const navigate = useNavigate();
  return (
    <div className="register-offer text-white">
      <Row>
        <Col
          md={9}
          className={` ${
            currentLocalization === "ar"
              ? "text-end banner-decription d-flex flex-column justify-content-end"
              : "text-start"
          } `}
        >
          <div>
            <span
              className={
                currentLocalization === "fr"
                  ? "offer-banner-span-fr"
                  : "offer-banner-span"
              }
            >
              {t("login.part19")}
            </span>
            <span
              className={
                currentLocalization === "fr"
                  ? "offer-banner-span-discount-fr"
                  : "offer-banner-span-discount"
              }
            >
              &nbsp;{t("login.part24")}
            </span>
            <span
              className={
                currentLocalization === "fr"
                  ? "offer-banner-span-fr"
                  : "offer-banner-span"
              }
            >
              &nbsp;{t("login.part25")}
            </span>
          </div>
          <div className="mb-0 offer-banner-sign">{t("login.part20")}</div>
        </Col>
        <Col
          md={3}
          className={`${
            currentLocalization === "ar"
              ? "banner-btn justify-content-start"
              : "justify-content-end"
          } d-flex align-items-center `}
        >
          <button
            onClick={() => {
              window.scroll(0, 0);
              navigate("/sign-up");
            }}
            className={
              currentLocalization === "fr"
                ? "banner-btn-in"
                : "reighter-button-offer"
            }
          >
            {t("login.part18")}
          </button>
        </Col>
      </Row>
    </div>
  );
};
export default RegisterOffer;
