import React, { useContext } from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import {
  FaFacebookF,
  FaTwitter,
  FaTiktok,
  FaInstagram,
  FaYoutube
} from "react-icons/fa";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../assets/images/logo.svg";
import { AuthUserContext } from "../../context/context";
const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { currentLocalization } = useContext(AuthUserContext);
  return (
    <div className="pb-5">
      <section className="container-fluid footer-bg ">
        <Row className="d-flex justify-content-center">
          <Col md={12}>
            <img
              src={logo}
              alt="logo"
              className="mt-5"
              width={200}
              onClick={() => {
                navigate("/");
                window.scroll(0, 0);
              }}
              style={{ cursor: "pointer" }}
            />
          </Col>
          <Row className={`d-flex justify-content-center ${currentLocalization === "fr" && "padding-set-footer"}`}>
          <Col
            className="mt-4 mb-2 ms-md-4 "
            md={1}
            xs={3}
            style={{ borderRight: "2px solid rgba(255, 255, 255, 0.14)" }}
          >
            <span
              className="footer-home-span"
              onClick={() => {
                navigate("/");
                window.scroll(0, 0);
              }}
              style={{ cursor: "pointer" }}
            >
              {t("navbar.home")}
            </span>
          </Col>
          <Col className="mt-4 mb-2 text-start ms-md-3" md={currentLocalization === "fr" ? 2 : 1} xs={3}>
            <span
              className="footer-home-span"
              onClick={() => {
                navigate(`/country-filter?country=SA`);
                window.scroll(0, 0);
              }}
              style={{ cursor: "pointer" }}
            >
              {t("navbar.offers")}
            </span>
          </Col>
          </Row>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col className="mt-3 mb-2" md={currentLocalization == "fr" || currentLocalization == "in" ? 10 : 8}   xs={11}>
            <span className="footer-sign-span ">{t("footer.part2")}</span>
            <br />
            <span className="footer-sign-span ">{t("footer.part4")}</span>
            <h3 className="footer-get-h3 mt-3 mb-0">{t("footer.part3")}</h3>
          </Col>
        </Row>
        <Row className="d-flex justify-content-evenly">
          <Col
            className="mt-3 mb-5 text-white d-flex justify-content-evenly"
            md={3}
            xs={12}
          >
            <a href="https://www.facebook.com/gleesimofficial" target="_blank" className="text-white"><FaFacebookF size={25} /></a>
            <a href="https://x.com/gleesimofficial" target="_blank" className="text-white"><FaTwitter size={25} /></a>
            <a href="https://www.tiktok.com/@gleesim" target="_blank" className="text-white"><FaTiktok size={25} /></a>
            <a href="https://www.instagram.com/gleesimofficial" target="_blank" className="text-white"><FaInstagram size={25} /></a>
            <a href="https://www.youtube.com/@gleesimofficial" target="_blank" className="text-white"><FaYoutube size={28}/></a>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default Footer;
