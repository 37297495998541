import React, { useContext, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RegisterSidebar from "../../component/RegisterSidebar";
import { useTranslation } from "react-i18next";
import Form from "react-bootstrap/Form";
import Input from "../../component/Input";
import { GrFormNextLink, GrFormPreviousLink } from "react-icons/gr";
import { AuthUserContext } from "../../context/context";
import Spinner from "react-bootstrap/esm/Spinner";
import { Link, useLocation, useNavigate } from "react-router-dom";
import verifyOTP from "../../services/auth/verifyOTP";
import { toast } from "react-toastify";
import { AUTH_TOKEN_KEY, USER_DETAILS } from "../../constants/localstorageKeys";
import resendOTP from "../../services/auth/resendOTP";
import authLogo from "../../assets/images/auth-logo.svg";
import LanguageSelect from "../../component/LanguageSelect";
import { IoMdArrowBack } from "react-icons/io";
const OtpVerification = ({setEventTriggered}) => {
  const { t } = useTranslation();
  const { currentLocalization, setUser, linkCopy,  topUpLink, setTopUpLink ,setLinkCopy, setToastId, toastId } =
    useContext(AuthUserContext);
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;
  const user = JSON.parse(localStorage.getItem(USER_DETAILS));
  const [validated, setValidated] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [otpValidation, setOtpValidation] = useState(false)
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const userEMail = email ? email : user?.email;
      if (!otpValue) {
        setValidated(true);
        return;
      }
      setOtpValidation(false)
      let lang;
      if (currentLocalization.startsWith("en-")) {
        lang = "en";
      } else {
        lang = currentLocalization;
      }
      setLoading(true);
      const object = {
        email: email,
        otp: otpValue,
        lang: lang,
      };
      if (userEMail) {
        const response = await verifyOTP(object);
        if (response) {
          const userDetails = {
            id: response?.data?.id,
            email: response?.data?.email,
            name: response?.data?.name,
            role: response?.data?.role,
            phoneNo: response?.data?.phone_no,
            otpVerified: response?.data?.otpVerified,
            countries: response?.data?.countries,
            referralCode: response?.data?.referralCode,
            parent_id: response?.data?.parent_id ?? null,

          };
          localStorage.setItem(USER_DETAILS, JSON.stringify(userDetails));
          localStorage.setItem(AUTH_TOKEN_KEY, response?.data?.token);
          setUser(userDetails);
          localStorage.setItem(AUTH_TOKEN_KEY, response?.data?.token);
          if (linkCopy) {
            navigate(`/offer-detail${linkCopy}`);
          }else if(topUpLink){
            navigate("/top-up");
          } else {
            const id = toast.success(response.message, {
              autoClose: false,
              closeOnClick: false,
              draggable: false,
              pauseOnHover: true
            });
            setToastId(id);
            navigate("/data-usage");
          }
        }
      } else {
        const id = toast.error(t("toastsMessage.part2"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } catch (err) {
      if (err.response?.status == 400) {
        const id = toast.error(err.response?.data?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      } else if (err.response?.status == 404) {
        const id = toast.error(err.response?.data?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      } else {
        const id = toast.error(err?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleOtpChange = (event) => {
    setOtpValidation(false)
    const { name, value } = event.target;
    if (value.length > 6) {
      setOtpValidation(true)
      return
    }
    setOtpValue(value);
  };
  const resendOtp = async () => {
    try {
      const userEMail = email ? email : user?.email;
      let lang;
      if (currentLocalization.startsWith("en-")) {
        lang = "en";
      } else {
        lang = currentLocalization;
      }
      const object = {
        email: userEMail,
        lang: lang,
      };
      if (userEMail) {
        const response = await resendOTP(object);
        if (response) {
          const id = toast.success(response?.message, {
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            pauseOnHover: true
          });
          setToastId(id);
        }
      } else {
        const id = toast.error(t("toastsMessage.part2"), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    } catch (err) {
      if (err.response?.status == 404) {
        const id = toast.error(err.response?.data?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      } else {
        const id = toast.error(err?.message, {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          pauseOnHover: true
        });
        setToastId(id);
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !loading) {
      if (toastId) setEventTriggered(true);
      event.preventDefault();
      handleSubmit(event);
    }
  };
  return (
    <Container fluid className="main-login-image">
      <Row className="row-login d-flex justify-content-center">
        <Col xs={12} md={6} className="loginImage p-0 ">
          <RegisterSidebar />
        </Col>
        <Col xs={12} md={6} className=" text-start login-side">
          <Row className="d-flex justify-content-center align-items-center pt-5">
            <Col
              md={9}
              className={`pt-0 p-md-0 p-4 d-flex align-items-center justify-content-between ${currentLocalization == "ar" && "flex-row-reverse"
                }`}
            >
              <Link to="/" onClick={() => {setLinkCopy('')
                setTopUpLink("")
              }}>
                <img src={authLogo} alt="" />
              </Link>
              <LanguageSelect />
            </Col>
            <Col xs={12} md={9} className="pt-4 p-0">
              <Link to="/sign-up" style={{ color: "#000" }}>
                <IoMdArrowBack size={30} />
              </Link>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center pt-5 pb-5 large-screen">
            <Col md={9} className="pt-0 p-md-0 p-4">
              <div
                style={{ marginTop: "15px" }}
                className={`p-0 ${currentLocalization == "ar" ? "text-end" : "text-start"
                  }`}
              >
                <h2 className="login-welcome">{t("otpVerification.part1")}</h2>
                <span className="login-information">
                  {t("otpVerification.part2")}
                </span>
                <Form
                  onSubmit={handleSubmit}
                  method="post"
                  onKeyDown={handleKeyDown}
                  className="mt-4"
                >
                  <Form.Group as={Col} md="12" className="d-flex flex-column">
                    <Input
                      labelClassName="label"
                      labelText={t("otpVerification.part5")}
                      type="number"
                      name="name"
                      value={otpValue}
                      onChange={handleOtpChange}
                      placeholder={t("otpVerification.part5")}
                      className={
                        currentLocalization == "ar"
                          ? "login-input-right"
                          : "login-input-left"
                      }
                    />
                    <div
                      className={
                        currentLocalization == "ar"
                          ? "text-end mt-2"
                          : "text-start mt-2"
                      }
                    >
                      {validated && !otpValue ? (
                        <span className="text-danger">
                          {t("validation.otpVerification")}
                        </span>
                      ) : otpValidation && (
                        <span className="text-danger">
                          {t("validation.otpValidation")}
                        </span>
                      )}
                    </div>
                  </Form.Group>
                  <div
                    className={`mt-1 d-flex align-items-center  ${currentLocalization == "ar"
                        ? "flex-row-reverse justify-content-end"
                        : "flex-row justify-content-end"
                      }`}
                  >
                    <div>
                      <span
                        className="forget-password"
                        onClick={resendOtp}
                        style={{ cursor: "pointer" }}
                      >
                        {t("otpVerification.part3")}
                      </span>
                    </div>
                  </div>
                  <Row style={{ marginTop: "20px" }}>
                    <Col>
                      <div className="d-grid gap-2 ">
                        <button
                          className={
                            loading
                              ? "btn-login-main-disabled"
                              : "btn-login-main"
                          }
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? (
                            <>
                              {currentLocalization == "ar" ? (
                                <>
                                  <span className="me-2">
                                    ... {t("login.part16")}
                                  </span>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                </>
                              ) : (
                                <>
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <span className="ms-2">
                                    {t("login.part16")} ...
                                  </span>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {currentLocalization == "ar" ? (
                                <>
                                  <GrFormPreviousLink
                                    size={27}
                                    className="me-1"
                                  />
                                  {t("otpVerification.part4")}
                                </>
                              ) : (
                                <>
                                  {t("otpVerification.part4")}
                                  <GrFormNextLink size={27} className="ms-1" />
                                </>
                              )}
                            </>
                          )}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default OtpVerification;
